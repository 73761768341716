import React, { useEffect } from 'react';
import './index.scss';
import { DateField, Image, Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Link } from 'react-router-dom';
import { RoutableLink } from '../../react/RoutableLink';

import i18n from 'i18next';

const FeaturedArticle = (props) => {
  const { fields, sitecoreContext, params, rendering } = props || {};
  const fielddata = fields || sitecoreContext.route.fields;
  const CharCount = params.CharCountShortDesc ? params.CharCountShortDesc : 0;

  return (
    <div className="featured-article" id="FeaturedArticle">
      <div className="featured-article-container">
        <ul className="featured-article__inner-contain">
          {fielddata?.Article?.length &&
            fielddata.Article.map((item, index) => {
              return (
                <li className="featured-article__inner-contain-items" key={index}>
                  <div className="featured-article__inner-contain-items-left-align">
                    <a
                      href={item?.url}
                      data-tag-item={
                        sitecoreContext?.route?.name +
                        '-ds:' +
                        rendering.dataSource +
                        '-Featured-Article-Image-Click'
                      }
                    >
                      <Image media={item?.fields?.ImageMedia} />
                      {fielddata?.Label?.value ? (
                        <span className="featured-article__inner-contain-items-left-align-label">
                          {fielddata?.Label?.value}
                        </span>
                      ) : (
                        <></>
                      )}
                    </a>
                  </div>
                  <div className="featured-article__inner-contain-items-right-align">
                    <div className="featured-article__inner-contain-items-right-align-content">
                      {item?.fields?.PublishedDate && params.ShowPublishedDate && (
                        <div className="featured-article__inner-contain-items-right-align-content-date">
                          <DateField
                            field={item?.fields?.PublishedDate}
                            render={(date) =>
                              `${date.toLocaleString('en-US', {
                                month: 'long',
                                day: 'numeric',
                                year: 'numeric',
                              })}`
                            }
                          />
                        </div>
                      )}
                      <h3 className="featured-article__inner-contain-items-right-align-content-title">
                        <a
                          href={item?.url}
                          data-tag-item={
                            sitecoreContext?.route?.name +
                            '-ds:' +
                            rendering.dataSource +
                            '-Featured-Article-Short-Title-Click'
                          }
                        >
                          <Text field={item?.fields?.ShortTitle} />
                        </a>
                      </h3>
                      {item?.fields?.FeaturedArticleDescription?.value ? (
                        <p className="featured-article__inner-contain-items-right-align-content-desc">
                          {item?.fields?.FeaturedArticleDescription.value.slice(0, CharCount)}
                        </p>
                      ) : (
                        <p className="featured-article__inner-contain-items-right-align-content-desc">
                          {item?.fields?.MetaDescription.value}
                        </p>
                      )}
                      <div className="featured-article__inner-contain-items-right-align-content-links">
                        <Link
                          to={item?.url}
                          data-tag-item={
                            sitecoreContext?.route?.name +
                            '-ds:' +
                            rendering.dataSource +
                            '-Featured-Article-' +
                            i18n.t('FeaturedArticle-Read-More')
                          }
                        >
                          <span>{i18n.t('FeaturedArticle-Read-More')}</span>
                        </Link>
                      </div>
                    </div>
                    {fielddata.ViewTags?.value == true ? (
                      <div className="featured-article__inner-contain-items-right-align-tags">
                        {item?.fields?.GlobalTags.length > 0 && (
                          <ul className="featured-article__inner-contain-items-right-align-tags-items">
                            {item.fields.GlobalTags.map((Global, index) => {
                              return (
                                <li
                                  key={index}
                                  className="featured-article__inner-contain-items-right-align-tags-items-lists"
                                >
                                  {Global.fields.Text.value}
                                </li>
                              );
                            })}
                          </ul>
                        )}
                        {item?.fields?.Industry.length > 0 && (
                          <ul className="featured-article__inner-contain-items-right-align-tags-items">
                            {item.fields.Industry.map((Industry, index) => {
                              return (
                                <li
                                  key={index}
                                  className="featured-article__inner-contain-items-right-align-tags-items-lists"
                                >
                                  {Industry.fields.Text.value}
                                </li>
                              );
                            })}
                          </ul>
                        )}
                        {item?.fields?.Service.length > 0 && (
                          <ul className="featured-article__inner-contain-items-right-align-tags-items">
                            {item.fields.Service.map((Service, index) => {
                              return (
                                <li
                                  key={index}
                                  className="featured-article__inner-contain-items-right-align-tags-items-lists"
                                >
                                  {Service.fields.Text.value}
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default withSitecoreContext()(FeaturedArticle);
