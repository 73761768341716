import React from 'react';
import './index.scss';
import GlobalSearchAutoComplete from '../GlobalSearchAutoComplete';

const GlobalSearch = ({ }) => {
  
  const handleSVGMobileClick = () => {
    const eleBody = document.getElementsByClassName('global-search-container_body')[1];
    const eleBodyMobile = document.getElementsByClassName('global-search-container-mobile_body')[0];
    const inputEle = document.getElementById('global-search_field');

    eleBodyMobile.classList.toggle('global-search-container-mobile_body_active');
    eleBody.classList.toggle('global-search-container_body_active');

    if (eleBodyMobile.classList.contains('global-search-container-mobile_body_active')) {
      inputEle.focus();
    }
  }

  return (
    <>
      <section className='global-search-container'>
        <GlobalSearchAutoComplete />
      </section>
      <section className='global-search-container-mobile'>
        <div className='global-search_wrapper'>
          <svg onClick={handleSVGMobileClick} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" fill='#fff' width="25px" height="48px"><path d="M 27 9 C 17.075 9 9 17.075 9 27 C 9 36.925 17.075 45 27 45 C 31.129213 45 34.9263 43.587367 37.966797 41.240234 L 51.048828 54.322266 C 51.952828 55.226266 53.418266 55.226266 54.322266 54.322266 C 55.226266 53.418266 55.226266 51.952828 54.322266 51.048828 L 41.240234 37.966797 C 43.587367 34.9263 45 31.129213 45 27 C 45 17.075 36.925 9 27 9 z M 27 13 C 34.719 13 41 19.281 41 27 C 41 34.719 34.719 41 27 41 C 19.281 41 13 34.719 13 27 C 13 19.281 19.281 13 27 13 z" /></svg>
        </div>
        <div className='global-search-container-mobile_body'>
          <div className='global-search-container-mobile_body_logo'>
            <div className='global-search-container-mobile_body_logo_wrapper'>
              <img src='https://cm.digital.wipfli.com/-/media/project/wipfli/wipfli-digital/logos/wipflidigitallogorgb01-1-2.png?h=204&iar=0&w=956&rev=18fcf796a11f40a0813d9ad90bef5494&hash=446D4C3BB4753B1788F18F6DB4DA94FD' />
            </div>
            <div className='global-search-container-mobile_body-close'>
              <svg onClick={handleSVGMobileClick} fill="#000000" height="20px" width="20px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 490 490">
                <polygon points="456.851,0 245,212.564 33.149,0 0.708,32.337 212.669,245.004 0.708,457.678 33.149,490 245,277.443 456.851,490 
	              489.292,457.678 277.331,245.004 489.292,32.337 "/>
              </svg>
            </div>
          </div>
          <GlobalSearchAutoComplete isMobile={true} />
        </div>
      </section>
    </>
  );
};

export default GlobalSearch;