import React, { useRef, useEffect, useState } from 'react';
import gsap from 'gsap';
import './index.scss';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { default as Button } from '../Button';
import { RoutableLink } from '../../react/RoutableLink';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// import required modules
import { Navigation, Autoplay, Pagination } from 'swiper';

const FlyInGallery_other = (props) => {
  const { Title, ViewAllCTA, children } = props.fields;
  const [delay, setDelay] = useState(5000);
  const FlyInSwiperRef = useRef(null);
  const FlyInSwiperTextRef = useRef(null);
  const FlyInSwiperNextRef = useRef(null);
  const FlyInSwiperPrevRef = useRef(null);

  const clipPath = {
    initial: 'inset(30% 25% 30% 35%)',
    final: 'inset(0% 0% 0% 0%)',
  };

  useEffect(() => {
    setInterval(() => {
      var testEvent = new PointerEvent('click');
      testEvent.nativeEvent = { detail: 1 };
      FlyInSwiperNextRef.current.click(testEvent);
    }, delay);
  }, [delay]);

  const setFlyInSwiperText = (swiper) => {
    FlyInSwiperTextRef.current.innerHTML =
      swiper.slides[swiper.activeIndex].querySelector('.description-container').innerHTML;
  };

  const setFlyInSwiperEndText = (swiper) => {
    FlyInSwiperTextRef.current.innerHTML =
      swiper.slides[swiper.activeIndex].querySelector('.description-container').innerHTML;

    gsap.from('.fly-in-gallery-text .description', 0.4, {
      autoAlpha: 0,
      y: 40,
      ease: 'Power1.easeOut',
    });
    gsap.from('.fly-in-gallery-text a', 0.4, {
      autoAlpha: 0,
      y: 10,
      delay: 0.15,
      ease: 'Power1.easeOut',
    });
  };

  const goToNextSlide = () => {
    gsap.to('.fly-in-gallery-imageMask', {
      clipPath: clipPath.initial,
      ease: 'Power1.easeIn',
      duration: 1,
    });

    gsap.to('.fly-in-gallery-text .description', 0.4, {
      autoAlpha: 0,
      y: -40,
      ease: 'Power1.easeIn',
    });
    gsap.to('.fly-in-gallery-text a', 0.4, {
      autoAlpha: 0,
      y: -10,
      ease: 'Power1.easeIn',
    });

    if (FlyInSwiperRef.current !== null && FlyInSwiperRef.current.swiper !== null) {
      setTimeout(() => {
        FlyInSwiperRef.current.swiper.slideNext();
      }, 1350);

      setTimeout(() => {
        gsap.to('.fly-in-gallery-imageMask', {
          clipPath: clipPath.final,
          ease: 'power1.out',
          duration: 1,
        });
        setDelay(5000);
      }, 1850);
    }
  };

  const goToPreviousSlide = () => {
    gsap.to('.fly-in-gallery-imageMask', {
      clipPath: clipPath.initial,
      ease: 'Power1.easeIn',
      duration: 1,
    });

    gsap.to('.fly-in-gallery-text .description', 0.4, {
      autoAlpha: 0,
      y: 40,
      ease: 'Power1.easeIn',
    });
    gsap.to('.fly-in-gallery-text a', 0.4, {
      autoAlpha: 0,
      y: 10,
      ease: 'Power1.easeIn',
    });

    if (FlyInSwiperRef.current !== null && FlyInSwiperRef.current.swiper !== null) {
      setTimeout(() => {
        FlyInSwiperRef.current.swiper.slidePrev();
      }, 1350);

      setTimeout(() => {
        gsap.to('.fly-in-gallery-imageMask', {
          clipPath: clipPath.final,
          ease: 'power1.out',
          duration: 1,
        });
        setDelay(5000);
      }, 1850);
    }
  };

  return (
    <section className="fly-in-gallery">
      <div className="fly-in-gallery-container">
        <div className="fly-in-gallery-left">
          <Text field={Title} tag="h2" className="fly-in-gallery-eyebrow" />
          <div className="fly-in-gallery-pagination">
            <div className="fly-in-gallery-controls">
              <button
                ref={FlyInSwiperPrevRef}
                onClick={goToPreviousSlide}
                className="fly-in-gallery-control--prev"
              >
                <span className="sr-only">Click to see Previous slide</span>
                <svg
                  role="presentation"
                  width="13"
                  height="21"
                  viewBox="0 0 13 21"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="m1.727 1.724 8.99 9.098-8.943 9.05"
                    stroke="#fff"
                    strokeWidth="2.25"
                    fill="none"
                    fillRule="evenodd"
                  ></path>
                </svg>
              </button>
              <button
                ref={FlyInSwiperNextRef}
                onClick={goToNextSlide}
                className="fly-in-gallery-control--next"
              >
                <span className="sr-only">Click to see Next slide</span>
                <svg
                  role="presentation"
                  width="13"
                  height="21"
                  viewBox="0 0 13 21"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="m1.727 1.724 8.99 9.098-8.943 9.05"
                    stroke="#ffffff"
                    strokeWidth="2.25"
                    fill="none"
                    fillRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            <div className="fly-in-gallery-count">
              <span className="fly-in-gallery-count-current">1</span> /
              <span className="fly-in-gallery-count-total">1</span>
            </div>
          </div>
          <div className="fly-in-gallery-textContainer">
            <div
              className="description-container fly-in-gallery-text"
              ref={FlyInSwiperTextRef}
            ></div>
          </div>
          <Button field={ViewAllCTA} variant={'fly-in-gallery-viewAll btn-black'} />
        </div>
      </div>
      <Swiper
        ref={FlyInSwiperRef}
        slidesPerView={1}
        loop={true}
        pagination={{
          type: 'fraction',
          el: '.fly-in-gallery-count',
        }}
        onInit={(swiper) => {
          setFlyInSwiperText(swiper);
        }}
        onSlideChangeTransitionEnd={(swiper) => {
          setFlyInSwiperEndText(swiper);
        }}
        speed={900}
        direction={'vertical'}
        modules={[Navigation, Pagination]}
      >
        {children?.length > 0 &&
          children.map(({ fields }, index) => {
            const { Title, Title1, CTA, GalleryImage } = fields || {};

            return (
              <SwiperSlide key={index}>
                <div className="fly-in-gallery-imageMask">
                  <div
                    className="fly-in-gallery-image"
                    style={{ backgroundImage: `url(${GalleryImage?.value?.src})` }}
                  ></div>
                </div>
                <div className="fly-in-gallery-textContainer sr-only">
                  <div className="description-container">
                    <Text field={Title} tag="span" className="description" />
                    <Text field={Title1} tag="span" className="description" />
                    <RoutableLink field={CTA} className="operationSmile" />
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
      </Swiper>
      <Button field={ViewAllCTA} variant={'fly-in-gallery-viewAll-mobile btn-right-transparent'} />
    </section>
  );
};

export default FlyInGallery_other;
