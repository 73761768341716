import React, { useEffect } from 'react';
import './index.scss';

const PageBreaker = ({ fields }) => {
  useEffect(() => {
    acceleratableMarquee('Marquee');
  }, []);

  const acceleratableMarquee = (rootId) =>
    (function (copyRootId) {
      const $root = document.querySelector(`#${copyRootId}`);
      if ($root) {
        const $track = $root.querySelector('.page-breaker__bg-title-inner-contain');
        let scrollOffset = 0;
        let trackPosition = 0;
        const textWidth = $root.querySelector('.page-breaker__bg-title').offsetWidth;
        let prevScrollOffset = scrollOffset;
        let prevDistance = 0;
        let current = 0;
        let goal = 0;
        // this constant represents the distance at which the deceleration speed is about the same as the marquee's speed
        const decelerationThreshold = 16;

        const reset = () => {
          goal -= current;
          current = 0;
          trackPosition += textWidth;
        };

        const lerp = (distance) => {
          // we will lerp 5% of the distance between
          // the current position and the distance travelled by the scroll
          current += distance * 0.05;
          trackPosition = current;
        };

        const marquee = () => {
          trackPosition -= 1;
          goal = trackPosition;
          current = trackPosition;
        };

        function loop() {
          scrollOffset = window.pageYOffset || window.scrollTop;
          const scrollDiff = Math.abs(scrollOffset - prevScrollOffset) || 0;

          goal -= scrollDiff;
          const distance = goal - current;

          if (trackPosition <= textWidth * -1) {
            reset();
          }

          if (
            // when accelerating we want lerp
            prevDistance > distance ||
            // we want lerp when decelerating until a specific threshold
            (prevDistance < distance && distance <= -decelerationThreshold)
          ) {
            lerp(distance);
          } else {
            marquee();
          }
         
          prevScrollOffset = scrollOffset;
          prevDistance = distance;
          $track.style.cssText = `transform:translateX(${trackPosition}px)`;
          //$track.css("transform", `translateX(${trackPosition}px)`);

          requestAnimationFrame(loop);
        }

        loop();
      }
    })(rootId);

  return (
  
      <section className="page-breaker" id="pageBreakerComp">
        <div className="page-breaker__container">
          {fields?.ForegroundText?.value && (
            <div className="page-breaker__inner-contain">
              <h2 className="page-breaker__title">
                <div dangerouslySetInnerHTML={{ __html: fields?.ForegroundText?.value }} />
              </h2>
            </div>
          )}
        </div>
        {fields?.BackgroundText?.value && (
          <div className="page-breaker__bg-title-contain" id="Marquee">
            <div className="page-breaker__bg-title-inner-contain">
              <div className="page-breaker__bg-title">{fields?.BackgroundText?.value}</div>
              <div className="page-breaker__bg-title" aria-hidden="true">
                {fields?.BackgroundText?.value}
              </div>
              <div className="page-breaker__bg-title" aria-hidden="true">
                {fields?.BackgroundText?.value}
              </div>  
              <div className="page-breaker__bg-title" aria-hidden="true">
                {fields?.BackgroundText?.value}
              </div> 
              <div className="page-breaker__bg-title" aria-hidden="true">
                {fields?.BackgroundText?.value}
              </div> 
              <div className="page-breaker__bg-title" aria-hidden="true">
                {fields?.BackgroundText?.value}
              </div>     
              <div className="page-breaker__bg-title" aria-hidden="true">
                {fields?.BackgroundText?.value}
              </div>
              <div className="page-breaker__bg-title" aria-hidden="true">
                {fields?.BackgroundText?.value}
              </div>
              <div className="page-breaker__bg-title" aria-hidden="true">
                {fields?.BackgroundText?.value}
              </div>
              <div className="page-breaker__bg-title" aria-hidden="true">
                {fields?.BackgroundText?.value}
              </div>
              <div className="page-breaker__bg-title" aria-hidden="true">
                {fields?.BackgroundText?.value}
              </div>
              <div className="page-breaker__bg-title" aria-hidden="true">
                {fields?.BackgroundText?.value}
              </div>
              <div className="page-breaker__bg-title" aria-hidden="true">
                {fields?.BackgroundText?.value}
              </div>
              <div className="page-breaker__bg-title" aria-hidden="true">
                {fields?.BackgroundText?.value}
              </div>
              <div className="page-breaker__bg-title" aria-hidden="true">
                {fields?.BackgroundText?.value}
              </div>       
            </div>
          </div>
        )}
      </section>    
  );
};

export default PageBreaker;
