import React, { useState, useEffect, useRef } from 'react';
import './index.scss';
import { Text, Image, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { RoutableRichText } from '../../react/RoutableRichText';

const ArticlePageAccordion = ({ fields, sitecoreContext, rendering }) => {
  const { children = [] } = fields;
  const ArticleAccordion = useRef();
  useEffect(() => {
    const Comp = document.querySelector('#ArticlePageAccordion');
    var anchorscall = Comp.querySelectorAll('a,button');
    Array.prototype.forEach.call(anchorscall, function (element, index) {
      if (element.innerText != '') {
        var id =
          sitecoreContext?.route?.name +
          '-ds:' +
          rendering.dataSource +
          '-Article-Page-Accordion-Item-' +
          element.innerText.replaceAll(' ', '-') +
          '-collapsed';
        element.setAttribute('id', id);
      }
    });
  }, []);
  const [active, setActive] = useState(null);
  const toggle = (index) => {
    const Accordion = ArticleAccordion?.current;
    const container = Accordion?.querySelectorAll('.article-page-accordion__items-lists')[index];
    const Title = Accordion?.querySelectorAll('.article-page-accordion__items-contain-title')[
      index
    ];
    const button = Accordion?.querySelectorAll('.article-page-accordion__items-contain')[index];
    if (active == index) {
      container.parentElement.parentElement.classList.remove('flex');
      Title.classList.remove('flex');

      button.setAttribute('id', button.id.replace('Expanded', 'collapsed'));
      button.setAttribute('data-tag-item', button.id.replace('Expanded', 'collapsed'));
      return setActive(null);
    } else {
      button.setAttribute('id', button.id.replace('collapsed', 'Expanded'));
      button.setAttribute('data-tag-item', button.id.replace('collapsed', 'Expanded'));
    }
    container.parentElement.parentElement.classList.add('flex');
    Title.classList.add('flex');
    setActive(index);
  };

  return (
    <div className="article-page-accordion" id="ArticlePageAccordion" ref={ArticleAccordion}>
      <div className="article-page-accordion-container">
        <ul className="article-page-accordion__items">
          {children.map((item, index) => {
            return (
              <li key={index} className="article-page-accordion__items-lists">
                <button
                  onClick={() => toggle(index)}
                  className="article-page-accordion__items-contain"
                  aria-expanded={active == index ? 'false' : 'true'}
                  aria-controls={`Accordion-${index}`}
                >
                  <div className="article-page-accordion__items-contain-title">
                    <Text field={item.fields.ArticleTitle} />
                    <span className="article-page-accordion__items-contain-title-subtext">
                      <Text field={item.fields.ArticleSubtext} />
                    </span>
                  </div>
                  <span className="article-page-accordion__items-contain-icon">
                    {active == index ? (
                      <svg
                        viewBox="0 0 30 29"
                        xmlns="http://www.w3.org/2000/svg"
                        className="article-page-accordion__items-contain-icon-collapse"
                      >
                        <g stroke="#0050FF" strokeWidth="2" fill="none" fillRule="evenodd">
                          <path strokeLinecap="square" d="M22 14.018H9" />
                          <path d="M15 28C7.267 28 1 21.956 1 14.5 1 7.043 7.267 1 15 1s14 6.044 14 13.5S22.733 28 15 28z" />
                        </g>
                      </svg>
                    ) : (
                      <svg
                        viewBox="0 0 30 30"
                        xmlns="http://www.w3.org/2000/svg"
                        className="article-page-accordion__items-contain-icon-expand"
                      >
                        <g stroke="#0050FF" strokeWidth="2" fill="none" fillRule="evenodd">
                          <path strokeLinecap="square" d="M15.5 8v13M22 14.5H9" />
                          <path d="M15 29C7.267 29 1 22.733 1 15S7.267 1 15 1s14 6.267 14 14-6.267 14-14 14z" />
                        </g>
                      </svg>
                    )}
                  </span>
                </button>
                <div
                  className={
                    active == index
                      ? 'article-page-accordion__items-contain-content-show'
                      : 'article-page-accordion__items-contain-content'
                  }
                  id={`Accordion-${index}`}
                >
                  {item?.fields?.ArtileItemTitle && (
                    <div className="article-page-accordion__items-contain-content-show-left">
                      <h4 className="article-page-accordion__items-contain-content-show-left-title">
                        {item.fields.ArtileItemTitle.value}
                      </h4>
                      <RoutableRichText
                        className="article-page-accordion__items-contain-content-show-left-desc"
                        field={item.fields.Description}
                      />
                    </div>
                  )}
                  {item?.fields?.Image?.value?.src && (
                    <div className="article-page-accordion__items-contain-content-show-right">
                      {item?.fields?.Link?.value?.href ? (
                        <a
                          href={item?.fields?.Link?.value?.href}
                          target={item?.fields?.Link?.value?.target?.replaceAll('|Custom', '')}
                        >
                          <Image media={item.fields.Image} />
                        </a>
                      ) : (
                        <Image media={item.fields.Image} />
                      )}
                    </div>
                  )}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
export default withSitecoreContext()(ArticlePageAccordion);
