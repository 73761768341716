import React, { useEffect } from 'react';
import { DateField, Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { RoutableRichText } from '../../react/RoutableRichText';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import { Navigation, A11y, Keyboard } from 'swiper';
import { ApplicationConstants } from '../ApplicationConstant/ApplicationConstant';
import Button from '../Button';
import './index.scss';
import i18n from 'i18next';

const ArticleCarousel = ({ fields, params, sitecoreContext, rendering }) => {
  const viewports = ApplicationConstants.viewports;
  const NoOfItems = params.NoofItemToDisplay || 11;
  const CharCount = params.CharCountShortDesc ? parseInt(params.CharCountShortDesc) : 0;
  const TempArticle = fields?.Articles?.length > 0 ? fields?.Articles : [];
  //  useEffect(() => {
  //   const Comp=document.querySelector('#ArticleCarousal');
  //   var anchorscall = Comp.querySelectorAll('a,button');
  //   Array.prototype.forEach.call(anchorscall, function (element, index) {
  //     if(element.innerHTML!="")
  //     {
  //     var id=sitecoreContext?.route?.name+"-Article-Carousal-"+element.innerHTML.replaceAll(" ", "-");

  //     element.setAttribute("id", id );
  //     }

  //     });
  // }, []);
  //console.log(array.fields?.PublishedDate.value);

  const Articles =
    TempArticle.length > 0
      ? fields.IsContextItem
        ? TempArticle.sort(
            (a, b) =>
              Date.parse(b.fields?.PublishedDate?.value) -
              Date.parse(a.fields?.PublishedDate?.value)
          ).slice(0, NoOfItems)
        : TempArticle
      : [];

  return (
    <section className="article-carousel" id="ArticleCarousal">
      <div className="article-carousel__container">
        <div className="article-carousel__inner-contain">
          {fields?.Title?.value && (
            <h4 className="article-carousel__title">
              <Text field={fields?.Title} />
            </h4>
          )}
          {Articles.length ? (
            <div className="article-carousel__btn-contain">
              <button className="article-carousel__swipper-btn-prev">
                <span className="sr-only">Prev</span>
                <svg
                  role="presentation"
                  xmlns="http://www.w3.org/2000/svg"
                  width="35"
                  height="35"
                  viewBox="0 0 35 35"
                >
                  <g
                    transform="matrix(-1 0 0 1 35 0)"
                    stroke="#0050FF"
                    strokeWidth="2"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <circle cx="17.5" cy="17.5" r="16.5" />
                    <path d="m13.462 8.974 8.974 8.998-8.928 8.951" />
                  </g>
                </svg>
              </button>
              <button className="article-carousel__swipper-btn-next">
                <span className="sr-only">Next</span>
                <svg
                  role="presentation"
                  xmlns="http://www.w3.org/2000/svg"
                  width="35"
                  height="35"
                  viewBox="0 0 35 35"
                >
                  <g stroke="#0050FF" strokeWidth="2" fill="none" fillRule="evenodd">
                    <circle cx="17.5" cy="17.5" r="16.5" />
                    <path d="m13.462 8.974 8.974 8.998-8.928 8.951" />
                  </g>
                </svg>
              </button>
            </div>
          ) : null}
        </div>
        {Articles.length ? (
          <div className="article-carousel__content">
            <Swiper
              modules={[Navigation, A11y, Keyboard]}
              spaceBetween={10}
              slidesPerView={1}
              slidesPerGroupSkip={0}
              centeredSlides={false}
              speed={900}
              a11y={true}
              keyboard={{
                enabled: true,
                onlyInViewport: false,
              }}
              navigation={{
                prevEl: '.article-carousel__swipper-btn-prev',
                nextEl: '.article-carousel__swipper-btn-next',
              }}
              breakpoints={{
                [viewports.medium]: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                  slidesPerGroup: 2,
                },
                [viewports.large]: {
                  slidesPerView: Articles.length <= 2 ? 2 : 3,
                  spaceBetween: 30,
                  slidesPerGroup: Articles.length <= 2 ? 2 : 3,
                },
              }}
              className="article-carousel__swipper"
            >
              {Articles.map((ArticleItem, index) => {
                return (
                  <SwiperSlide key={`ArticleItem-${index}`}>
                    <div className={`article-carousel__swipper-content`}>
                      {ArticleItem?.url ? (
                        <Link
                          to={ArticleItem?.url}
                          className="article-carousel__swipper-content-link"
                          data-tag-item={
                            sitecoreContext?.route?.name +
                            '-ds:' +
                            rendering.dataSource +
                            '-' +
                            'Artical-Carousal-Items-' +
                            ArticleItem?.fields?.ShortTitle?.value
                          }
                        >
                          {ArticleItem?.fields?.PublishedDate && params.ShowPublishedDate && (
                            <div className="article-carousel__swipper-date">
                              <DateField
                                field={ArticleItem?.fields?.PublishedDate}
                                tag="small"
                                render={(date) =>
                                  `${date.toLocaleString('en-US', {
                                    month: 'long',
                                    day: 'numeric',
                                    year: 'numeric',
                                  })}`
                                }
                              />
                            </div>
                          )}
                          {ArticleItem?.fields?.ShortTitle?.value && (
                            <h5 className="article-carousel__swipper-title">
                              <Text field={ArticleItem?.fields?.ShortTitle} />
                            </h5>
                          )}
                          {ArticleItem?.fields?.ShortDescription?.value && (
                            <div className="article-carousel__swipper-description">
                              <RoutableRichText
                                field={{
                                  ...ArticleItem?.fields?.ShortDescription,
                                  value: ArticleItem?.fields?.ShortDescription.value.slice(
                                    0,
                                    CharCount
                                  ),
                                }}
                              />
                            </div>
                          )}
                          {ArticleItem?.url && (
                            <div className="article-carousel__swipper-link-contain">
                              <div className="article-carousel__swipper-link">
                                <span>
                                  {ArticleItem?.DisplayName ??
                                    i18n.t('ArticlePageCarousal-ArticleItem-CTA-Title')}
                                </span>
                                <svg
                                  role="presentation"
                                  width="12"
                                  height="21"
                                  viewBox="0 0 12 21"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="m1 1.5 9 9.023L1.047 19.5"
                                    stroke="#0050FF"
                                    strokeWidth="2"
                                    fill="none"
                                    fillRule="evenodd"
                                  />
                                </svg>
                              </div>
                            </div>
                          )}
                        </Link>
                      ) : (
                        <>
                          {ArticleItem?.fields?.PublishedDate && params.ShowPublishedDate && (
                            <div className="article-carousel__swipper-date">
                              <DateField
                                field={ArticleItem?.fields?.PublishedDate}
                                render={(date) =>
                                  `${date.toLocaleString('en-US', {
                                    month: 'long',
                                    day: 'numeric',
                                    year: 'numeric',
                                  })}`
                                }
                              />
                            </div>
                          )}
                          {ArticleItem?.fields?.ShortTitle?.value && (
                            <h5 className="article-carousel__swipper-title">
                              <Text field={ArticleItem?.fields?.ShortTitle} />
                            </h5>
                          )}
                          {ArticleItem?.fields?.ShortDescription?.value && (
                            <div className="article-carousel__swipper-description">
                              <RoutableRichText
                                field={{
                                  ...ArticleItem?.fields?.ShortDescription,
                                  value: ArticleItem?.fields?.ShortDescription.value.slice(
                                    0,
                                    CharCount
                                  ),
                                }}
                              />
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        ) : null}
        {fields?.CTA?.value?.text && (
          <div className="article-carousel__link-contain">
            <Button
              field={fields.CTA}
              variant={'btn-white'}
              datatag={
                sitecoreContext?.route?.name +
                '-ds:' +
                rendering.dataSource +
                '-' +
                'Arical-Carousal-' +
                fields?.CTA?.value?.text
              }
            />
          </div>
        )}
      </div>
    </section>
  );
};
export default withSitecoreContext()(ArticleCarousel);
