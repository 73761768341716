import { useEffect } from 'react';
import ReactDOM from 'react-dom';
export const BODY_TOP = 'body_top';
export const BODY_BOTTOM = 'body_bottom';
export const HEAD = 'head';
export default function useInjectScript(scriptArray = [], location = BODY_BOTTOM) {
  useEffect(() => {
    scriptArray.forEach(({ id, url, code, async, defer, attributes, nonscriptcode }) => {
      if (url || code) {
        const script = document.createElement('script');
        script.id = id;
        // url or code, not both at the same time
        if (url) {
          script.src = url;
        } else if (code) {
          script.innerHTML = code;
        }
        if (attributes) {
          attributes?.split('&')?.forEach((attr) => {
            const [facetName, facetValue] = attr?.split('=');
            if (facetName && facetName !== '') {
              if (facetValue === undefined) {
                script.setAttribute(facetName, '');
              } else {
                script.setAttribute(facetName, facetValue);
              }
            }
          });
        }
        if (location === BODY_BOTTOM) {
          // append to the bottom of <body>
          document.body.appendChild(script);
        } else if (location === BODY_TOP) {
          // place at the top of <body>
          document.body.prepend(script);
        } else {
          // appends to the <head>
          document.head.appendChild(script);
        }
      }
      if (nonscriptcode) {
        const range = document.createRange();
        if (location === BODY_BOTTOM) {
          // append to the bottom of <body>
          document.body.appendChild(range.createContextualFragment(nonscriptcode));
        } else if (location === BODY_TOP) {
          // place at the top of <body>
          document.body.prepend(range.createContextualFragment(nonscriptcode));
        } else {
          // appends to the <head>
          document.head.appendChild(range.createContextualFragment(nonscriptcode));
        }
      }
    });
    return function cleanup() {
      scriptArray.forEach(({ id }) => {
        if (!id) return;
        const script = document.getElementById(id);
        if (script) script.parentNode.removeChild(script);
        const nonscript = document.getElementById(id + '_div');
        if (nonscript) script.parentNode.removeChild(nonscript);
      });
    };
  }, [scriptArray, location]);
}
