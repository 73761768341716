export const ApplicationConstants = {  

    viewports: {
        /** Zero. Minimum width of extra-small devices. This value is provided for reference only. Do not use. */
        extraSmall: 0,
        /** Minimum width of small devices. */
        small: 540,
        /** Minimum width of medium devices. */
        medium: 720,
        /** Minimum width of large devices. */
        large: 960,
        /** Minimum width of extra-large devices. */
        extraLarge: 1140,
        /** Minimum width of extra-extra-large devices. */
        extraExtraLarge: 1440,
    },
}