import React, { useRef } from 'react';
import {
  Image,
  Text,
  withSitecoreContext,
  withPlaceholder,
} from '@sitecore-jss/sitecore-jss-react';
import { RoutableRichText } from '../../react/RoutableRichText';
import Button from '../Button';
import EbookModal from '../EbookModal';

import './index.scss';

const ArticleCta = (props) => {
  const { fields, sitecoreContext, formPlaceholder, rendering } = props || {};
  const modalRef = useRef(null);
  const openCTAModal = () => {
    modalRef?.current.classList.add('show-ebook-modal');
    const captchaInput = document.querySelector(
      '.article-cta textarea[data-static="captchaInput"]'
    );
    if (captchaInput) {
      captchaInput.focus();
    }
  };

  function linkText(cta) {
    if (cta.value.linktype == 'internal' && !cta.value.text) {
      // takes the page route, splits and takes the page name after the last /, replaces all - with blank space to create
      // words, and then capitalizes each word. i.e. home/test/generic-page => Generic Page
      return cta.value.href
        .split('/')
        .pop()
        .replaceAll('-', ' ')
        .replace(/(^\w{1})|(\s+\w{1})/g, (l) => l.toUpperCase());
    }
    return cta.value.text;
  }

  const isEditing = sitecoreContext?.pageEditing;
  return (
    <section className="article-cta" id="ArticleCTA">
      {fields?.Title?.value && <h4 className="article-cta__title">{fields.Title.value}</h4>}
      <div className="article-cta__container">
        {(fields?.Image?.value?.src || fields?.ImageCaption?.value) && (
          <div className="article-cta__image-contain">
            {fields?.Image?.value?.src && (
              <Image field={fields.Image} className="article-cta__image" />
            )}
            {fields?.ImageCaption?.value && (
              <div className="article-cta__image-caption">
                <Text field={fields.ImageCaption} />
              </div>
            )}
          </div>
        )}
        <div className="article-cta__inner-contain">
          {fields?.Description?.value && (
            <div className="article-cta__description">
              <RoutableRichText field={fields.Description} />
            </div>
          )}
          <div className="article-cta__contain">
            {fields?.Cta?.value?.href && (
              <>
                {fields?.ShowDownloadIcon?.value === true ? (
                  <a
                    className="article-cta__link"
                    href={fields?.Cta?.value?.href}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                    id={
                      sitecoreContext?.route?.name +
                      '-ds:' +
                      rendering.dataSource +
                      '-Artical-CTA-' +
                      fields?.Cta?.value?.text
                    }
                  >
                    {linkText(fields?.Cta)}
                    <svg
                      role="presentation"
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="24"
                      viewBox="0 0 21 24"
                    >
                      <path
                        d="M16.025 23.875h-11.5a4.312 4.312 0 0 1-4.312-4.313v-3.234a1.077 1.077 0 1 1 2.156 0v3.235c0 1.185.97 2.156 2.156 2.156h11.5c1.186 0 2.156-.97 2.156-2.157v-3.234a1.078 1.078 0 0 1 2.157 0v3.235a4.314 4.314 0 0 1-4.313 4.312zm-5.013-7.48c-.413.391-1.06.391-1.475 0l-6.11-5.75a1.073 1.073 0 0 1-.046-1.522 1.073 1.073 0 0 1 1.522-.05l4.294 4.039V1.953a1.077 1.077 0 1 1 2.156 0v11.159l4.291-4.036a1.077 1.077 0 1 1 1.477 1.57l-6.11 5.75z"
                        fill="#0050ff"
                        fillRule="nonzero"
                      />
                    </svg>
                  </a>
                ) : (
                  <>
                    {fields?.Cta?.value?.href && (
                      <Button
                        field={fields?.Cta}
                        variant={'btn-white'}
                        id={
                          sitecoreContext?.route?.name +
                          '-ds:' +
                          rendering.dataSource +
                          '-Arical-CTA-' +
                          fields?.Cta?.value?.text
                        }
                      />
                    )}
                  </>
                )}
              </>
            )}
            {(formPlaceholder || [])?.find(
              (component) => component?.props?.rendering?.componentName === 'JssSitecoreForm'
            ) ? (
                <button className="article-cta__button" onClick={openCTAModal}>
                  {fields?.FormCTALabel?.value}
                </button>
              ) : null}
              
            <EbookModal className={`${isEditing ? 'editing' : ''}`} ref={modalRef}>
              {formPlaceholder}
            </EbookModal>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withSitecoreContext()(
  withPlaceholder({
    placeholder: 'jss-form',
    prop: 'formPlaceholder',
  })(ArticleCta)
);
