import React from 'react';
import './index.scss';
import { Text } from '@sitecore-jss/sitecore-jss-react';

function ServerError({ fields }) {
  const { Title, Description } = fields;
  return(
  <section className="server-error">
    {Title?.value && 
    <h1><Text field = {Title} />
      </h1>}
      {Description?.value &&
      <p>{Description?.value}</p>}
  </section>
  )
}

export default ServerError;
