import { useQuery } from '@apollo/react-hooks';
import { GLOBAL_SEARCH } from '../queries/GlobalSearchQuery';

export const useGLobalSearchQuery = ({
    page = 1,
    perPage = 10,
    rootItem = '01B4180A-FF6C-4EA5-98C0-CB6BEE9BC01F',
    params = {}
}) => {

    const searchKeyword = params.keyword || "";

    const variables = {
        keyword: searchKeyword,
        rootItem,
        sortBy: 'publishedDate',
        sortDesc: true,
    };

    const { data, ...rest } = useQuery(GLOBAL_SEARCH, { variables });

    return { data: data?.search?.results, ...rest };
};
