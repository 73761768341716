import React, { useEffect } from 'react';
import { Image, Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Link } from 'react-router-dom';
import { RoutableRichText } from '../../react/RoutableRichText/index.js';
import './index.scss';

const TileList = ({ fields, sitecoreContext, rendering }) => {
  const selectedItems = fields?.Tiles?.some((item) => item !== null)
    ? fields?.Tiles
    : fields?.children;
  function linkText(cta) {
    if (cta.value.linktype == 'internal' && !cta.value.text) {
      // takes the page route, splits and takes the page name after the last /, replaces all - with blank space to create
      // words, and then capitalizes each word. i.e. home/test/generic-page => Generic Page
      return cta.value.href
        .split('/')
        .pop()
        .replaceAll('-', ' ')
        .replace(/(^\w{1})|(\s+\w{1})/g, (l) => l.toUpperCase());
    }
    return cta.value.text;
  }

  return (
    <section className="tile-list__comp" id="tileList">
      <div className="tile-list__container">
        {fields?.Title?.value && (
          <h2 className="tile-list__title">
            {' '}
            <Text field={fields?.Title} />
          </h2>
        )}
        {selectedItems?.length && (
          <div className="tile-list__contain">
            <ul className="tile-list">
              {selectedItems.map((tileList, key) => {
                return (
                  <li className="tile-list__item" key={`tileListItem-${key}`}>
                    {tileList?.fields?.Cta?.value?.href ? (
                      <Link
                        to={tileList.fields.Cta.value.href}
                        className="tile-list__item-clickable-link"
                        target={
                          tileList?.fields?.Cta?.value?.linktype == 'external' ? '__blank' : ''
                        }
                        rel={
                          tileList?.fields?.Cta?.value?.linktype == 'external'
                            ? 'noopener noreferrer'
                            : ''
                        }
                        data-tag-item={
                          sitecoreContext?.route?.name +
                          '-ds:' +
                          rendering.dataSource +
                          '-Tiles-List-Items-' +
                          tileList.fields?.Cta?.value?.text
                        }
                      >
                        <div className="tile-list__item-contain">
                          {tileList?.fields?.Image?.value?.src && (
                            <div className="tile-list__item-image-contain">
                              <Image media={tileList?.fields?.Image} id={`tileListImage-${key}`} />
                            </div>
                          )}
                          {tileList?.fields?.Title?.value && (
                            <h3 className="tile-list__item-title">
                              <Text field={tileList?.fields?.Title} />
                            </h3>
                          )}
                          {tileList?.fields?.Description?.value && (
                            <div className="tile-list__item-description">
                              {' '}
                              <RoutableRichText field={tileList?.fields?.Description} />
                            </div>
                          )}
                          {tileList?.fields?.Cta?.value?.href && (
                            <div className="tile-list__item-link-contain">
                              <div className="tile-list__item-link">
                                <span>{linkText(tileList?.fields?.Cta)}</span>
                                <svg
                                  role="presentation"
                                  width="13"
                                  height="21"
                                  viewBox="0 0 13 21"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="m1.727 1.724 8.99 9.098-8.943 9.05"
                                    stroke="#0050ff"
                                    strokeWidth="2.25"
                                    fill="none"
                                    fillRule="evenodd"
                                  />
                                </svg>
                              </div>
                            </div>
                          )}
                        </div>
                      </Link>
                    ) : (
                      <div className="tile-list__item-contain">
                        {tileList?.fields?.Image?.value?.src && (
                          <div className="tile-list__item-image-contain">
                            <Image media={tileList?.fields?.Image} id={`tileListImage-${key}`} />
                          </div>
                        )}
                        {tileList?.fields?.Title?.value && (
                          <h3 className="tile-list__item-title">
                            <Text field={tileList?.fields?.Title} />
                          </h3>
                        )}
                        {tileList?.fields?.Description?.value && (
                          <div className="tile-list__item-description">
                            {' '}
                            <RoutableRichText field={tileList?.fields?.Description} />
                          </div>
                        )}
                      </div>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </section>
  );
};
export default withSitecoreContext()(TileList);
