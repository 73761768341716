import React, { useEffect } from 'react';
import './index.scss';
import { Text,withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import { RoutableRichText } from '../../react/RoutableRichText/index.js';
import { RoutableLink } from '../../react/RoutableLink';
import { Link } from 'react-router-dom';

function Footer(props) {
  const { fields,sitecoreContext,rendering } = props;
  const { Title = {}, CopyrightText = {}, Logohref = {}, Logo = {}, children = [] } = fields;

  const copyRightLinkItems =
    children?.find((item) => item.templateId === 'd9a8d0e2-2dae-4062-8155-b7a383f5bca2') || {};
  const footerLinkItems =
    children?.find((item) => item.templateId === '621c7a42-20b1-40bb-8ece-bd807959f2e6') || {};

  const copyRightLinks = copyRightLinkItems?.fields?.children;
  const FooterLists = footerLinkItems?.fields?.children;
 
  return (
    <div className="footer" id="Footer">
      <div className="footer-container">
        <div className="footer__logo">
          <h3 className="footer__logo-title">
            <Text field={Title} />
          </h3>
          {Logohref?.value?.href ? (
            <div className="footer__logo-link">
              <Link to={Logohref?.value?.href} data-tag-item={"Footer-Logo-"+rendering.dataSource+"-"+Logohref?.value?.text}>
                {' '}
                <Image media={Logo} className="footer__logo-link-img" />
              </Link>
            </div>
          ) : (
            <div className="footer__logo-link">
              <Image media={Logo} className="footer__logo-link-img" />
            </div>
          )}
        </div>
        <div className="footer__menu">
          {FooterLists?.length && (
            <ul className="footer__menu-items">
              {FooterLists?.map(({ fields }, index) => {
                return (
                  <li key={`MenuItems-${index}`} className="footer__menu-items-lists">
                    <RoutableLink field={fields.Link} className="footer__menu-items-lists-links" datatag={"Footer-Menu-"+rendering.dataSource+"-"+fields?.Link?.value?.text} />
                  </li>
                );
              })}
            </ul>
          )}
        </div>
        <div className="footer__copy">
          <RoutableRichText field={CopyrightText} />
          {copyRightLinks?.length > 0 &&
            copyRightLinks?.map(({ fields }, index) => {
              return (
                <div key={`CopyrightLink-${index}`}>
                  <RoutableLink field={fields.Link} datatag={"Footer-CopyRight-Link-"+rendering.dataSource+"-"+fields?.Link?.value?.text}  />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default Footer;
