import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Placeholder,
  VisitorIdentification,
  isEditorActive,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
//import { NavLink } from 'react-router-dom';
//import { withTranslation } from 'react-i18next';
import deepEqual from 'deep-equal';
import Helmet from 'react-helmet';
import useInjectScript, { BODY_BOTTOM, BODY_TOP,PageBODY_TOP,PageBODY_BOTTOM } from './react/hooks/useInjectScript';
// Using bootstrap is completely optional. It's used here to provide a clean layout for samples,
// without needing extra CSS in the sample app. Remove it in package.json as well if it's removed here.
import 'bootstrap/dist/css/bootstrap.css';
import './scss/global.scss';
import HeaderScriptTags from './react/HeaderScriptTags';

/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/

const Layout = ({ route, sitecoreContext }) => {
  useInjectScript(sitecoreContext?.script_tags_body_top, BODY_TOP);
  useInjectScript(sitecoreContext?.script_tags_body_bottom, BODY_BOTTOM)
  useInjectScript(sitecoreContext?.page_script_tags_body_top, BODY_TOP);
  useInjectScript(sitecoreContext?.page_script_tags_body_bottom, BODY_BOTTOM)
  var OgImage=sitecoreContext.OgImage,PublishedDate='';

  const history = useHistory();

  const authors =
    sitecoreContext.route.fields?.Author?.length > 0
      ? sitecoreContext.route.fields?.Author.map((prodData) => prodData.fields?.Name?.value).join(
          ', '
        )
      : '';
  const service =
    sitecoreContext.route.fields?.Service?.length > 0
      ? sitecoreContext.route.fields?.Service.map((prodData) => prodData.fields?.Text?.value).join(
          ', '
        )
      : '';
  const industry =
    sitecoreContext.route.fields?.Industry?.length > 0
      ? sitecoreContext.route.fields?.Industry.map((prodData) => prodData.fields?.Text?.value).join(
          ', '
        )
      : '';
  const globaltags =
    sitecoreContext.route.fields?.GlobalTags?.length > 0
      ? sitecoreContext.route.fields?.GlobalTags.map(
          (prodData) => prodData.fields?.Text?.value
        ).join(', ')
      : '';
  useEffect(() => {
    /*      add experience editor class to body element if experience editor is active    */
    if (isEditorActive()) {
      
      const body = document.querySelector('body');
      body.classList.add('experience-editor');
      const html = document.querySelector('html');
      html.classList.add('experience-editor');
    }
  }, [route, sitecoreContext]);

  useEffect(() => {
    var anchorscall = document.querySelectorAll('a,button');
    Array.prototype.forEach.call(anchorscall, function (element, index) {
      if(element?.id)
      element.setAttribute("data-tag-item", element.id);
      });
        window.dispatchEvent(new Event('wipfli:routechange'));

        
          if (history.action === "PUSH") {
            window.scrollTo({
              top:0,behavior:"instant"
            })
          }    
        


  }, [route]);

  return (
    <React.Fragment>
      {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
      <HeaderScriptTags scriptArray={sitecoreContext?.script_tags_head} />
      <HeaderScriptTags scriptArray={sitecoreContext?.page_script_tags_head} />
     
      <Helmet>
      
        <title>
          {(route.fields && route.fields.BrowserTitle && route.fields.BrowserTitle.value) || 'Page'}
        </title>
        <meta
          name="description"
          content={
            (route.fields && route.fields.MetaDescription && route.fields.MetaDescription.value) ||
            ''
          }
        />
        <meta
          name="keywords"
          content={
            (route.fields && route.fields.MetaKeywords && route.fields.MetaKeywords.value) || ''
          }
        />
        <meta
          name="type"
          content={(route.fields && route.fields.PageType && route.fields.PageType.value) || ''}
        />
        <meta
          name="canonical"
          href={
            (route.fields && route.fields.CanonicalURL && route.fields.CanonicalURL.value) ||
            sitecoreContext.itemUrl
          }
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Wipfli Digital" />
        <meta
          property="og:title"
          content={(route.fields && route.fields.OgTitle && route.fields.OgTitle.value) || ''}
        />
        <meta
          property="og:type"
          content={(route.fields && route.fields.OgType && route.fields.OgType.value) || 'website'}
        />
        <meta property="og:url" content={sitecoreContext.itemUrl} />
        <meta
          property="og:description"
          content={
            (route.fields && route.fields.MetaDescription && route.fields.MetaDescription.value) ||
            ''
          }
        />
        <meta
          property="og:image"
          content={(route.fields && route.fields.OgImage && route.fields.OgImage.value?.src) || OgImage}
        />
        <meta
          name="twitter:card"
          content={(route.fields && route.fields.TwitterCard && route.fields.TwitterCard.value) || ''}
        />
        <meta
          name="twitter:title"
          content={
            (route.fields && route.fields.BrowserTitle && route.fields.BrowserTitle.value) || 'Page'
          }
        />
        <meta
          name="twitter:description"
          content={
            (route.fields && route.fields.MetaDescription && route.fields.MetaDescription.value) ||
            ''
          }
        />
        <meta
          name="twitter:image"
          content={(route.fields && route.fields.OgImage && route.fields.OgImage.value?.src) || OgImage}
        />
        <meta
          property="article:published_time"
          content={
            
            sitecoreContext.CreatedDate
          }
        />
        <meta property="article:modified_time" content={sitecoreContext.ModifiedDate} />
        <meta
          name="robots"
          content={
            (route.fields && route.fields.NoIndex && route.fields.NoIndex?.value
              ? 'noindex'
              : '') || ''
          }
        />
        <meta
          name="robots"
          content={
            (route.fields && route.fields.NoFollow && route.fields.NoFollow?.value
              ? 'nofollow'
              : '') || ''
          }
        />
        <meta property="article:author" content={authors} />
        <meta property="Industry" content={industry} />
        <meta property="Service" content={service} />
        <meta property="Global " content={globaltags} />
      </Helmet>

      {/*
      VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
      If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
      For XM (CMS-only) apps, this should be removed.

      VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
    */}
      <VisitorIdentification />

      {/* tODO rEMOVE */}
      {/* root placeholder for the app, which we add components to using route data */}
      {/* <div className="MainPanel">
      <Placeholder name="jss-main" rendering={route} />
    </div> */}

      <Placeholder name="jss-header" rendering={route} />
      <main id="main">
        <Placeholder name="jss-main" rendering={route} />
      </main>
      <Placeholder name="jss-footer" rendering={route} />
    </React.Fragment>
  );
};

// We don't want to re-render `Layout` when route is changed but layout data is not loaded
// Layout will be re-rendered only when layout data is changed
const propsAreEqual = (prevProps, nextProps) => {
  if (deepEqual(prevProps.route, nextProps.route)) return true;

  return false;
};

//export default React.memo(propsAreEqual, withSitecoreContext()(Layout));
export default withSitecoreContext()(Layout);
