import React from 'react';
import './index.scss';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Link } from 'react-router-dom';

const HeroPage = ({fields}) => {
  return (
    <section className="hero-page">
      <div className='hero-page_wrapper'>
        <div className='hero-page_wrapper_info'>
          <div className='hero-page_wrapper_info_eyebrow'>
            <Link to={fields?.Breadcrumb?.value?.href} target={fields?.Breadcrumb?.value?.linktype == 'external' ? '__blank' : ''} rel={fields?.Breadcrumb?.value?.linktype == 'external' ? 'noopener noreferrer' : ''}>
               {fields?.Breadcrumb?.value?.text ? fields?.Breadcrumb?.value?.text : fields?.Breadcrumb?.value?.href}
            </Link>
          </div>
          <div className='hero-page_wrapper_info_title' style={{ color: fields?.TitleColor?.fields?.ColorCode?.value || '#000' }}>
            {fields?.Title?.value}
          </div>
          <div className='hero-page_wrapper_info_sub-title'>
            {fields?.Subtitle?.value}
          </div>
          <div className='hero-page_wrapper_info_descp'>
            {fields?.Description?.value}
          </div>
        </div>
        <div className='hero-page_wrapper_img'>
          <div className='hero-page_wrapper_img_block'>
            <img src={fields?.Image?.value?.src} alt={fields?.Image?.value?.alt} title={fields?.Image?.value?.title} />
          </div>
        </div>
      </div>
    </section>
  )
};

export default withSitecoreContext()(HeroPage);