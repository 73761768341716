import React, { useRef, useEffect, useState } from 'react';
import './index.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar, Pagination, Navigation, EffectCoverflow } from 'swiper';
import { Text, Image } from '@sitecore-jss/sitecore-jss-react';
import { RoutableRichText } from '../../react/RoutableRichText';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

const ImageGalleryTemplateId = '9d074775-68c7-40ba-918e-43378776ee9a';
const TextAndImageStepTemplateId = '2ec7dd26-25f5-4a11-b0e0-232c99757fac';
const TwoColumnTextStepTemplateId = '3a296e5f-d3ec-4404-b365-fc648185cd41';

const getTheme = (ThemeField) => {
  return ThemeField?.fields?.Text?.value === 'Light' ? 'light' : '';
};

const TwoColumnText = ({ fields }) => {
  const { Title, Column1Description, Column2Description, Theme } = fields;
  const theme = getTheme(Theme);

  return (
    <section className={`step-list-two-column ${theme}`}>
      <div className="step-list-two-column__content">
        <Text tag="h3" field={Title} />
        <div className="step-list-two-column__columns">
          <div>
            <RoutableRichText field={Column1Description} />
          </div>
          <div>
            <RoutableRichText field={Column2Description} />
          </div>
        </div>
      </div>
    </section>
  );
};

const Gallery = ({ fields }) => {
  const { Title, Description, Theme, children } = fields;
  const theme = getTheme(Theme);

  const SteplistImageideoRef = useRef();
  const [openModal, setOpenModal] = useState(false);
  const playVideo = (e) => {
    const target = e.currentTarget;
    const VideoURL = target.getAttribute('data-video-url');
    setOpenModal(!openModal);
    const newVideoUrl = `${VideoURL}?autoplay=1&color=0050ff`;
    SteplistImageideoRef?.current.setAttribute('src', newVideoUrl);
  };
  const closeVideoModal = () => {
    setOpenModal(!openModal);
    SteplistImageideoRef?.current.setAttribute('src', '');
  };
  return (
    <section className={`step-list-gallery ${theme}`}>
      <div className="step-list-gallery__content">
        <Text tag="h3" field={Title} />
        <div className="step-list-gallery__description">
          <RoutableRichText field={Description} />
        </div>
        <Swiper
          modules={[Scrollbar, Pagination, Navigation, EffectCoverflow]}
          grabCursor={true}
          effect={'coverflow'}
          centeredSlides={true}
          slidesPerView={1.25}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 400,
            modifier: 1,
            slideShadows: false,
          }}
          a11y={true}
          scrollbar={{
            draggable: true,
            dragSize: 90,
          }}
          pagination={{
            type: 'fraction',
          }}
          navigation={true}
          loop={false}
          breakpoints={{ 1024: { slidesPerView: 1.5 } }}
          slideToClickedSlide={true}
        >
          {children?.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                {item?.fields?.editable ? (
                  item?.fields?.Variations?.value === 'Video' ? (
                    <img src={item?.fields?.VideoThumbnail?.value.src} />
                  ) : item?.fields?.LottieImage?.value != '' ? (
                    item?.fields?.LottieImage?.value.toLowerCase().includes('<lottie-player') ? (
                      <div dangerouslySetInnerHTML={{ __html: item?.fields?.LottieImage?.value }} />
                    ) : (
                      <Player
                        autoplay
                        speed={1.5}
                        loop
                        src={item?.fields?.LottieImage?.value}
                      ></Player>
                    )
                  ) : (
                    <img src={item?.fields?.ImageMedia?.value.src} />
                  )
                ) : item?.fields?.Variations?.value === 'Video' &&
                  item?.fields?.VideoThumbnail?.value?.src != null ? (
                  <button
                    className="featured-content__inner-contain-right-thumbnail"
                    data-video-url={
                      item?.fields?.Video?.value?.src
                        ? item?.fields?.Video?.value?.src
                        : item?.fields?.VideoUrl?.value
                    }
                    onClick={playVideo}
                  >
                    <img src={item?.fields?.VideoThumbnail?.value?.src} />
                    <svg
                      aria-hidden="true"
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.5 0a8.5 8.5 0 1 1 0 17 8.5 8.5 0 0 1 0-17zM5.224 4.395v8.127l8.127-4.063-8.127-4.064z"
                        fill="#FFF"
                        fillRule="evenodd"
                      />
                    </svg>
                  </button>
                ) : (
                  item?.fields?.Variations?.value === 'Image' &&
                  (item?.fields?.Image?.value != null || item?.fields?.LottieImage?.value != '') &&
                  (item?.fields?.LottieImage?.value != '' ? (
                    item?.fields?.LottieImage?.value.toLowerCase().includes('<lottie-player') ? (
                      <div dangerouslySetInnerHTML={{ __html: item?.fields?.LottieImage?.value }} />
                    ) : (
                      <Player
                        autoplay
                        speed={1.5}
                        loop
                        src={item?.fields?.LottieImage?.value}
                      ></Player>
                    )
                  ) : (
                    <Image
                      field={{ value: { ...item?.fields?.Image?.value, width: '', height: '' } }}
                    />
                  ))
                )}
              </SwiperSlide>
            );
          })}
        </Swiper>

        <div className={`step-list__video-modal-container ${openModal ? 'active' : ''}`}>
          <div className="step-list__video-modal">
            <button
              className="step-list__video-modal-closebtn"
              title="click here to close the modal"
              onClick={closeVideoModal}
            />
            <div className="step-list__video-modal-contain">
              <iframe
                className="step-list__video"
                allow="autoplay; fullscreen"
                playsInline
                allowFullScreen
                ref={SteplistImageideoRef}
                title="video"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const TwoColumnImage = ({ fields }) => {
  const { Title, Description, Image: image, Theme } = fields;
  const theme = getTheme(Theme);

  return (
    <section className={`step-list-two-column ${theme}`}>
      <div className="step-list-two-column__content">
        <div className="step-list-two-column__columns">
          <div>
            <Text tag="h3" field={Title} />
            <div>
              <RoutableRichText field={Description} />
            </div>
          </div>
          <div>
            {fields?.LottieImage?.value != '' ? (
              <div class="lottieImage">
                {fields?.LottieImage?.value.toLowerCase().includes('<lottie-player') ? (
                  <div dangerouslySetInnerHTML={{ __html: fields?.LottieImage?.value }} />
                ) : (
                  <Player autoplay speed={1.5} loop src={fields?.LottieImage?.value}></Player>
                )}
              </div>
            ) : image?.editable ? (
              <img src={image?.value?.src} />
            ) : (
              <Image field={image} />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

const renderStep = (fields, templateId, index) => {
  if (templateId === ImageGalleryTemplateId) {
    return <Gallery fields={fields} key={`step-list-item${index}`} />;
  } else if (templateId === TextAndImageStepTemplateId) {
    return <TwoColumnImage fields={fields} key={`step-list-item${index}`} />;
  } else if (templateId === TwoColumnTextStepTemplateId) {
    return <TwoColumnText fields={fields} key={`step-list-item${index}`} />;
  }
  return null;
};

const StepList = (props) => {
  const { Title, children = [] } = props?.fields || {};
  const theme = children.length > 0 ? getTheme(children[0]?.fields?.Theme) : '';

  return (
    <div className="step-list">
      <div className={`step-list__title ${theme}`}>
        <Text tag="h2" field={Title} />
      </div>
      {children?.length > 0 &&
        children.map((child, index) => renderStep(child.fields, child.templateId, index))}
    </div>
  );
};

export default StepList;
