import React, { useEffect, useRef } from 'react';
import './index.scss';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { RoutableRichText } from '../../react/RoutableRichText';

function Testimonial({ fields }) {
  const { Quote, Author, Role, Organisation, enableAnimation } = fields;
  const testimonialRef = useRef();


  const handleFadeIn = () => {
    if (testimonialRef.current.getBoundingClientRect()?.top < window.innerHeight) {
      testimonialRef.current.classList.add('testimonial-fadein');
    }
  };

  useEffect(() => {
    if (testimonialRef?.current) {
        window.addEventListener('scroll', handleFadeIn);
      handleFadeIn();
      }
    
    return () => {
      window.removeEventListener('scroll', handleFadeIn);
    };
  }, []);

  return (
    <div
      className="testimonial"
      ref={testimonialRef}
      data-animation-enable={`${enableAnimation?.value === true ? 'true' : 'false'}`}
    >
      <div className="testimonial-container">
        {Quote?.value && 
        <h5 className="testimonial__quote">
           <div dangerouslySetInnerHTML={{ __html: Quote.value }} />
        </h5>}
        {Author?.value && (
          <p className="testimonial__author">
            <Text field={Author} />
          </p>
        )}
        {Role?.value && (
          <span className="testimonial__role">
            <Text field={Role} />
            {Organisation.value && Role.value ? <>{', '}</> : null}
          </span>
        )}
        {Organisation?.value && (
          <span className="testimonial__organisation">
            <Text field={Organisation} />
          </span>
        )}
      </div>
    </div>
  );
}

export default Testimonial;
