import React, { useEffect, useMemo, useState, useRef } from 'react';
import { DateField, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import i18n from 'i18next';
import './index.scss';
import { Pagination } from '../../react/Pagination';
import { useArticleReleasesQuery } from '../../graphql/hooks/useArticleReleasesQuery';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import fetchNodeDescendants from '../../sitecoreService';
import Constants from '../../Constants';

const ArticleListing = ({ sitecoreContext = {}, params }) => {
  let [articleQueryParams, setArticleQueryParams] = useState({});
  const articleReleasesPerPage = params?.PageSize || 10;
  const [currentData, setCurrentData] = useState('');
  const [page, setPage] = useState(1);
  const CharCount = params.CharCountShortDesc ? parseInt(params.CharCountShortDesc) : 0;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [selectedGlobalTags, setSelectedGlobalTags] = useState([]);

  const { data: articleReleasesData } = useArticleReleasesQuery({
    page,
    perPage: articleReleasesPerPage,
    rootItem: sitecoreContext?.itemId,
    params: articleQueryParams,
  });

  const articleListRef = useRef(null);
  const executePageCall = () => {
    window.scrollTo({
      top: articleListRef.current.offsetTop - 50,
      behavior: 'smooth',
    });
  };
  const pageCount = useMemo(
    () => Math.ceil(currentData?.totalCount / articleReleasesPerPage),
    [currentData?.totalCount]
  );
  const articleReleaseMap = currentData;

  const [data, setData] = useState({ industry: [], services: [], globalTags: [] });
  useEffect(() => {
    const nodePaths = [
      Constants.SitecoreNodeID.SeoIndustryTags,
      Constants.SitecoreNodeID.SeoServiceTag,
      Constants.SitecoreNodeID.SeoGlobalTags,
    ];

    fetchNodeDescendants(nodePaths).then((responses) => {
      const [industry, services, globalTags] = responses.map(
        (response) => response && response.item.children
      );

      setData({
        industry: industry || [],
        services: services || [],
        globalTags: globalTags || [],
      });
    });
  }, []);

  function convertToGuid(hexString) {
    // Ensure the input is a 32-character hexadecimal string
    if (hexString.length !== 32) {
      throw new Error('Invalid input length. Must be a 32-character hexadecimal string.');
    }

    // Format the string into a GUID format
    const guid = [
      hexString.slice(0, 8),
      hexString.slice(8, 12),
      hexString.slice(12, 16),
      hexString.slice(16, 20),
      hexString.slice(20),
    ].join('-');

    return '{' + guid + '}';
  }

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    if (articleReleasesData) {
      setCurrentData(articleReleasesData);
    }
  }, [articleReleasesData]);

  const setCheckedCount = (target, count) => {
    document.querySelector('#' + target).textContent = count == 0 ? '' : count;
  };

  const handleCheckboxChange = (event) => {
    const { checked } = event.target;

    if (checked) {
      setCheckboxes((prevState) => [...prevState, event.target.dataset.name]);
    } else {
      const updateCheckboxLabels = checkboxes.filter(x => x != event.target.dataset.name);
      setCheckboxes(updateCheckboxLabels);
    }

    const indCheckboxes = document.querySelectorAll(".filter-data input[type='checkbox']:checked").length > 0;
    setIsDisabled(!indCheckboxes);

    if (event.target.classList.contains('industry')) {
      const selected = document.querySelectorAll('.industry:checked');
      setCheckedCount('industry-count', selected.length);
      const selectedValues = Array.from(selected).map((checkbox) => checkbox.value);
      setSelectedIndustry(selectedValues);
    }

    if (event.target.classList.contains('service')) {
      const selected = document.querySelectorAll('.service:checked');
      setCheckedCount('service-count', selected.length);
      const selectedValues = Array.from(selected).map((checkbox) => checkbox.value);
      setSelectedServices(selectedValues);
    }

    if (event.target.classList.contains('global')) {
      const selected = document.querySelectorAll('.global:checked');
      setCheckedCount('global-count', selected.length);
      const selectedValues = Array.from(selected).map((checkbox) => checkbox.value);
      setSelectedGlobalTags(selectedValues);
    }
  };

  const clearModel = () => {
    window.location.reload();
  };

  const handleFilter = async () => {
    setArticleQueryParams({ service: selectedServices?.join('|') || '', globalTags: selectedGlobalTags?.join('|') || '', industry: selectedIndustry?.join('|') || '' });
    closeModal();
  };

  const handleToggle = (event) => {
    console.log(event.target);
    const ele = event.target.nextSibling;
    ele.classList.toggle('show');
  };

  return (
    <section className="articlelisting" id="ArticleListing" ref={articleListRef}>
      <div className="articlelisting__container">
        <div class="filter-by-topic">
          <div class="container">
            <h4 class="container_title"> Filter By Topic</h4>
            <span class="container_btn" onClick={openModal}>
              <svg
                viewBox="0 0 30 30"
                xmlns="http://www.w3.org/2000/svg"
                class="accordion__items-contain-icon-expand"
              >
                <g fill="none" stroke="#000" stroke-width="2" fill-rule="evenodd">
                  <path d="M15.5 8v13M22 14.5H9" stroke-linecap="square" />
                  <path d="M15 29C7.267 29 1 22.733 1 15S7.267 1 15 1s14 6.267 14 14-6.267 14-14 14z" />
                </g>
              </svg>
            </span>
          </div>
          <div class="search-result">
            {checkboxes && checkboxes.map((item, index) => {
              return (
                <label class="search-result_label" key={'checked_' + index}>
                  <input type="checkbox" class="search-result_label_chekbox" checked /> {item}
                </label>
              )
            })}
          </div>
        </div>

        <Modal isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Filter Modal">
          <div class="resource-hub-industry-service-filter-v2">
            <div class="filter-data show" id="filterData">
              <div class="row filter-header">
                <div class="col-md-6">
                  <a
                    className={isDisabled ? 'clear-filter disabled' : 'clear-filter'}
                    onClick={clearModel}
                  >
                    Clear filters
                  </a>
                </div>
                <div class="col-md-6">
                  <button class="btn-apply-filter" onClick={handleFilter}>
                    Apply filters
                  </button>
                </div>
              </div>
              <div class="row filter-details">
                {data.industry.length > 0 && (
                  <div class="col-md-4 col-industry pr-0">
                    <div class="menu-col">
                      <span class="filter-label">Filter by industry</span>
                      <span class="filter-count" id="industry-count"></span>
                      <div class="acc-header" data-filter-header="drop-head" onClick={handleToggle}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M17.2499 8L12.0028 12.7232L6.75459 8L5.58325 9.30083L12.0028 15.0776L18.4213 9.30083L17.2499 8Z"
                            fill="#0050FF"
                          ></path>
                        </svg>
                      </div>
                      <div class="acc-details industry-checkbox">
                        <ul>
                          {data.industry.map((item) => (
                            <li>
                              <div class="custom-control custom-checkbox">
                                <input
                                  value={item.id.toLowerCase()}
                                  type="checkbox"
                                  class="custom-control-input industry"
                                  id={item.id}
                                  onChange={handleCheckboxChange}
                                  checked={selectedIndustry.includes(item.id.toLowerCase())}
                                  data-name={item.name}
                                />
                                <label class="custom-control-label" for={item.id}>
                                  {item.name}
                                </label>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
                {data.services.length > 0 && (
                  <div class="col-md-4 col-service pr-0 ">
                    <div class="menu-col">
                      <span class="filter-label">Filter by services</span>
                      <span class="filter-count" id="service-count"></span>
                      <div class="acc-header" data-filter-header="drop-head" onClick={handleToggle}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M17.2499 8L12.0028 12.7232L6.75459 8L5.58325 9.30083L12.0028 15.0776L18.4213 9.30083L17.2499 8Z"
                            fill="#0050FF"
                          ></path>
                        </svg>
                      </div>
                      <div class="acc-details service-checkbox">
                        <ul>
                          {data.services.map((item) => (
                            <li>
                              <div class="custom-control custom-checkbox">
                                <input
                                  value={item.id.toLowerCase()}
                                  type="checkbox"
                                  class="custom-control-input service"
                                  id={item.id}
                                  onChange={handleCheckboxChange}
                                  checked={selectedServices.includes(item.id.toLowerCase())}
                                  data-name={item.name}
                                />
                                <label class="custom-control-label" for={item.id}>
                                  {item.name}
                                </label>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
                {data.globalTags.length > 0 && (
                  <div class="col-md-4 col-service pr-0 ">
                    <div class="menu-col">
                      <span class="filter-label">Filter by Global Tags</span>
                      <span class="filter-count" id="global-count"></span>
                      <div class="acc-header" data-filter-header="drop-head" onClick={handleToggle}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M17.2499 8L12.0028 12.7232L6.75459 8L5.58325 9.30083L12.0028 15.0776L18.4213 9.30083L17.2499 8Z"
                            fill="#0050FF"
                          ></path>
                        </svg>
                      </div>
                      <div class="acc-details service-checkbox">
                        <ul>
                          {data.globalTags.map((item) => (
                            <li>
                              <div class="custom-control custom-checkbox">
                                <input
                                  value={item.id.toLowerCase()}
                                  type="checkbox"
                                  class="custom-control-input global"
                                  id={item.id}
                                  onChange={handleCheckboxChange}
                                  checked={selectedGlobalTags.includes(item.id.toLowerCase())}
                                  data-name={item.name}
                                />
                                <label class="custom-control-label" for={item.id}>
                                  {item.name}
                                </label>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal>

        <ul className="articlelisting__list">
          {articleReleaseMap?.items?.map((result, index) => (
            <li className="articlelisting__list-item" key={`ArticleListItem-${index}`}>
              <div className="articlelisting__list-item-content">
                <div className="articlelisting__list-item-link">
                  {result?.item?.publishedDate?.jss?.value && params?.ShowPublishedDate && (
                    <div className="articlelisting__list-item-date">
                      <DateField
                        field={result?.item?.publishedDate?.jss}
                        tag="small"
                        render={(date) =>
                          `${date.toLocaleString('en-US', {
                            month: 'long',
                            day: 'numeric',
                            year: 'numeric',
                          })}`
                        }
                      />
                    </div>
                  )}
                  {result?.item?.shortTitle?.jss?.value && (
                    <h2 className="articlelisting__list-item-title">
                      <a href={result?.item?.url}>{result?.item?.shortTitle?.jss?.value}</a>
                    </h2>
                  )}
                  {result?.item?.shortDescription?.jss?.value && (
                    <div className="articlelisting__list-item-innercontent">
                      {result?.item?.shortDescription?.jss?.value.slice(0, CharCount)}
                    </div>
                  )}
                  <div className="articlelisting__list-item-outercontent">
                    <div className="articlelisting__list-item-links-contain">
                      <Link
                        to={result?.item?.url}
                        className="articlelisting__list-item-links"
                        data-tag-item={
                          sitecoreContext?.route?.name +
                          '-Article-List-' +
                          result?.item?.shortTitle?.jss?.value
                        }
                      >
                        <span>{i18n.t('ArticleList-LearMore')}</span>
                        <svg
                          role="presentation"
                          width="13"
                          height="21"
                          viewBox="0 0 13 21"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="m1.727 1.724 8.99 9.098-8.943 9.05"
                            stroke="#0050ff"
                            strokeWidth="2.25"
                            fill="none"
                            fillRule="evenodd"
                          />
                        </svg>
                      </Link>
                    </div>
                    {(result?.item?.service?.targetItems.length ||
                      result?.item?.industry?.targetItems.length ||
                      result?.item?.globalTags?.targetItems.length) > 0 &&
                      params?.ShowTags && (
                        <div className="articlelisting__list-item-link-list-contain">
                          <ul className="articlelisting__list-item-link-list">
                            {result?.item?.service?.targetItems?.map((services, index) => (
                              <li key={`ArticleListItem-services-${index}`}>{services.name}</li>
                            ))}
                            {result?.item?.industry?.targetItems?.map((industries, index) => (
                              <li key={`ArticleListItem-industries-${index}`}>{industries.name}</li>
                            ))}
                            {result?.item?.globalTags?.targetItems?.map((globalTag, index) => (
                              <li key={`ArticleListItem-globalTags-${index}`}>{globalTag.name}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
        <Pagination
          className="press-releases-index__pagination"
          onPageClick={setPage}
          page={page}
          executePageCall={executePageCall}
          pageCount={pageCount}
          currentpage={sitecoreContext?.route?.name}
        />
      </div>
    </section>
  );
};

export default withSitecoreContext()(ArticleListing);
