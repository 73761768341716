import React, { useEffect } from 'react';
import { Image, Text, withSitecoreContext, Link } from '@sitecore-jss/sitecore-jss-react';
import { RoutableRichText } from '../../react/RoutableRichText';
import { RoutableLink } from '../../react/RoutableLink';
import './index.scss';

const ArticlePageAuthor = (props) => {
  const { fields, sitecoreContext } = props || {};
  const fielddata = fields || sitecoreContext.route.fields;
  if (fielddata?.Author?.length > 0) {
    return (
      <ul className="article-page-author__list" id="ArticlePageAuthor">
        {fielddata?.Author?.map((author, index) => {
          return (
            <li className="article-page-author__list-item" key={`author-${index}`}>
              <div className="article-page-author">
                {author?.fields?.Image?.value.src && (
                  <div className="article-page-author__image-contain">
                    <Image media={author.fields.Image} id={`author-image-${index}`} />
                  </div>
                )}
                <div className="article-page-author__contain">
                  {author?.fields?.Name?.value && (
                    <h5 className="article-page-author__name">
                      <Text field={author.fields.Name} />
                    </h5>
                  )}
                  {author?.fields?.Role?.value && (
                    <div className="article-page-author__role">
                      <Text field={author.fields.Role} />
                    </div>
                  )}
                  {author?.fields?.Description?.value && (
                    <div className="article-page-author__description">
                      <RoutableRichText field={author.fields.Description} />
                    </div>
                  )}
                  {author?.fields?.ViewMoreCta?.value.href && (
                    <div className="article-page-author__link-contain">
                      <RoutableLink
                        className="article-page-author__link"
                        field={author.fields.ViewMoreCta}
                        datatag={
                          sitecoreContext?.route?.name +
                          '-Article-Page-Author-' +
                          author?.fields?.ViewMoreCta?.value.text
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    );
  }
};

export default withSitecoreContext()(ArticlePageAuthor);
