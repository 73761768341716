import React from 'react';
import { Placeholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const GenericContent = ({ rendering }) => {
  return(
  <div>
  <Placeholder name="jss-generic-content" rendering={rendering} />
  </div>
  );
  };



export default withSitecoreContext()(GenericContent);