import React from 'react';
import './index.scss';
import { RoutableLink } from '../../react/RoutableLink';

function Button({field,variant,datatag}){

  return (
    <RoutableLink field={field} className={`button ${variant || ''}`} datatag={datatag}/>      
  )
}

export default Button;