import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { usePagination } from '../hooks/usePagination';
import './index.scss';

export const Pagination = ({
  accessibleLabel = 'Page',
  className,
  onPageClick,
  page: controlledPage,
  executePageCall,
  pageCount = 1,
  rangeCount = 4,
  separator = '...',
  currentpage = '',
}) => {
  const [internalPage, setInternalPage] = useState(1);
  const page = controlledPage || internalPage;
  const pages = usePagination({ page, pageCount, rangeCount, separator });
  const nextButtonRef = useRef();
  const PrevButtonRef = useRef();
  const handlePageClick = (nextPage, isLast, isFirst) => {
    setInternalPage(nextPage);
    executePageCall();
    onPageClick?.(nextPage);
    nextButtonRef.current.disabled = isLast ? true : false;
    PrevButtonRef.current.disabled = isFirst ? true : false;
  };

  if (!pageCount || pageCount < 2) return <></>;

  return (
    <nav className={classNames('pagination', className)} aria-label={accessibleLabel}>
      <div className="pagination__list__item__previous">
        <button
          ref={PrevButtonRef}
          aria-label="Previous Page"
          disabled={page == 1 ?? true}
          onClick={() => handlePageClick(page - 1, false, page === 2)}
          type="button"
          data-tag-item={currentpage + '-Article-List-Previous-' + page}
        >
          <svg
            role="presentation"
            width="13"
            height="21"
            viewBox="0 0 13 21"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m1.727 1.724 8.99 9.098-8.943 9.05"
              stroke="#0050ff"
              strokeWidth="2.25"
              fill="none"
              fillRule="evenodd"
            />
          </svg>
          <span className="pagination__previous">Prev</span>
        </button>
      </div>
      <ul className="pagination__list heading6 bold">
        <li className="pagination__list__item pagination__list__item--display">
          <strong>
            {page}/{pageCount}
          </strong>
        </li>
        {pages.map((label, index) => {
          if (typeof label !== 'number') {
            return (
              <li className="pagination__list__item pagination__list__item--separator" key={index}>
                {label}
              </li>
            );
          }

          const isCurrent = label === page;
          const isLast = label === pageCount;

          return (
            <li
              className={classNames('pagination__list__item pagination__list__item--page', {
                'pagination__list__item--active': isCurrent,
              })}
              key={index}
            >
              <button
                aria-current={isCurrent ? 'page' : false}
                aria-label={[isLast && 'Last Page', `Page ${label}`].filter(Boolean).join(', ')}
                onClick={() => handlePageClick(label, isLast, label === 1)}
                type="button"
                data-tag-item={currentpage + '-Article-List-Page-' + label}
              >
                {label}
              </button>
            </li>
          );
        })}
      </ul>
      <div className="pagination__list__item__next">
        <button
          aria-label="Next Page"
          onClick={() => handlePageClick(page + 1, page + 1 === pageCount, false)}
          type="button"
          ref={nextButtonRef}
          data-tag-item={currentpage + '-Article-List-Next-' + page}
        >
          <span className="pagination__next">Next</span>
          <svg
            role="presentation"
            width="13"
            height="21"
            viewBox="0 0 13 21"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m1.727 1.724 8.99 9.098-8.943 9.05"
              stroke="#0050ff"
              strokeWidth="2.25"
              fill="none"
              fillRule="evenodd"
            />
          </svg>
        </button>
      </div>
    </nav>
  );
};
