import React, { useRef, useEffect, useState } from 'react';
import './index.scss';
import { Text, withSitecoreContext, Link } from '@sitecore-jss/sitecore-jss-react';
import { RoutableRichText } from '../../react/RoutableRichText';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper';
import Button from '../Button';

function FeaturedContent({ fields, sitecoreContext, rendering }) {
  const {
    PreText,
    Title,
    Description,
    CTA,
    children = [],
    Theme,
    VideoURL,
    VideoThumbnail,
    ImageMedia,
    VideoMedia,
    ContentVariations,
    Alignment,
  } = fields;

  const FeaturevideoRef = useRef();
  const [openModal, setOpenModal] = useState(false);
  useEffect(() => {
    const Comp = document.querySelector('#FeaturedContent');
    var anchorscall = Comp.querySelectorAll('a');
    Array.prototype.forEach.call(anchorscall, function (element, index) {
      if (element.innerHTML != '') {
        var id =
          sitecoreContext?.route?.name +
          '-ds:' +
          rendering.dataSource +
          '-Featured-Content-' +
          element.innerHTML.replaceAll(' ', '-');

        element.setAttribute('id', id);
      }
    });
  }, []);
  const newVideo = VideoMedia?.value?.src ? VideoMedia?.value?.src : VideoURL?.value;

  const playVideo = (e) => {
    const target = e.currentTarget;
    const VideoURL = target.getAttribute('data-video-url');
    setOpenModal(!openModal);
    const newVideoUrl = `${VideoURL}?autoplay=1&color=0050ff`;
    FeaturevideoRef?.current.setAttribute('src', newVideoUrl);
  };

  const closeVideoModal = () => {
    setOpenModal(!openModal);
    FeaturevideoRef?.current.setAttribute('src', '');
  };

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return `<button aria-label="Change to slide-${
        index + 1
      }" class=" ${className} "><span class = 'swiper-pagination-button' ></span></button>`;
    },
  };
  return (
    <div
      className={`featured-content ${
        Theme?.value == 'Dark' ? 'featured-content__dark-theme' : ''
      } ${Alignment?.value == 'Left' ? 'featured-content__right-align' : ''}`}
    >
      <div className="featured-content-container" id="FeaturedContent">
        <div className="featured-content__inner-contain">
          <div className="featured-content__inner-contain-left">
            {PreText && (
              <h4 className="featured-content__inner-contain-left-pretext">
                <Text field={PreText} />
              </h4>
            )}
            {Title && (
              <h2 className="featured-content__inner-contain-left-title">
                <Text field={Title} />
              </h2>
            )}
            {Description && (
              <div className="featured-content__inner-contain-left-description">
                <RoutableRichText field={Description} />
              </div>
            )}
            {CTA?.value?.href && (
              <Button field={CTA} variant={'featured-content__desktop_link btn-white'} />
            )}
          </div>
          <div className="featured-content__inner-contain-right">
            {ContentVariations?.value == 'Video' || ContentVariations?.value == 'Image' ? (
              <div>
                {ContentVariations?.value == 'Video' ? (
                  <button
                    className="featured-content__inner-contain-right-thumbnail"
                    //data-video-url={fields?.VideoUrl?.value==""?VideoMedia?.value?.src:VideoURL?.value}
                    data-video-url={
                      fields?.VideoMedia?.value?.src
                        ? fields?.VideoMedia?.value?.src
                        : fields?.VideoURL?.value
                    }
                    onClick={playVideo}
                  >
                    <img src={VideoThumbnail?.value?.src} />

                    <svg
                      aria-hidden="true"
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.5 0a8.5 8.5 0 1 1 0 17 8.5 8.5 0 0 1 0-17zM5.224 4.395v8.127l8.127-4.063-8.127-4.064z"
                        fill="#FFF"
                        fillRule="evenodd"
                      />
                    </svg>
                  </button>
                ) : (
                  <div>
                    {ImageMedia?.editable ? (
                      <img src={ImageMedia?.value?.src} />
                    ) : (
                      <>
                        {CTA?.value?.href ? (
                          <Link field={CTA}>
                            <Image media={ImageMedia} />
                          </Link>
                        ) : (
                          <Image media={ImageMedia} />
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <Swiper
                modules={[Pagination, Autoplay]}
                autoplay={{ delay: 3000, disableOnInteraction: false }}
                speed={900}
                pagination={pagination}
                slidesPerView={3}
                spaceBetween={70}
                slidesPerGroup={3}
              >
                {children.map((item, index) => {
                  return (
                    <SwiperSlide key={index}>
                      {item.fields?.ImageUrl?.value?.href ? (
                        <Link field={item.fields.ImageUrl}>
                          {item?.fields?.editable ? (
                            <img
                              src={item?.fields?.ImageMedia?.value.src}
                              className="featured-content__inner-contain-right-icons"
                              alt=""
                            />
                          ) : (
                            <Image
                              field={item?.fields?.ImageMedia}
                              className="featured-content__inner-contain-right-icons"
                            />
                          )}
                        </Link>
                      ) : (
                        <>
                          {item?.fields?.editable ? (
                            <img
                              src={item?.fields?.ImageMedia?.value.src}
                              className="featured-content__inner-contain-right-icons"
                              class="featured-content__inner-contain-right-icons"
                            />
                          ) : (
                            <Image
                              field={item?.fields?.ImageMedia}
                              className="featured-content__inner-contain-right-icons"
                            />
                          )}
                        </>
                      )}
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            )}
            {ContentVariations?.value == 'Video' && (
              <div
                className={`featured-content__video-modal-container ${openModal ? 'active' : ''}`}
              >
                <div className="featured-content__video-modal">
                  <button
                    className="featured-content__video-modal-closebtn"
                    title="click here to close the modal"
                    onClick={closeVideoModal}
                  />
                  <div className="featured-content__video-modal-contain">
                    {newVideo.match(/(?:www\.)?player.vimeo|vimeo.com|youtu(?:\.be|be\.com)/i) !=
                    null ? (
                      <iframe
                        className="featured-content__video"
                        allow="autoplay; fullscreen"
                        playsInline
                        allowFullScreen
                        ref={FeaturevideoRef}
                        title="video"
                      />
                    ) : (
                      <video
                        className="featured-content__video"
                        playsInline
                        allowFullScreen
                        autoPlay
                        loop
                        controls
                        ref={FeaturevideoRef}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {CTA?.value?.href && (
          <Button field={CTA} variant={'featured-content__mobile_link btn-white'} />
        )}
      </div>
    </div>
  );
}
export default withSitecoreContext()(FeaturedContent);
