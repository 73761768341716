import React, { useState, useEffect, useRef } from 'react';
import { Image, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { RoutableLink } from '../../react/RoutableLink';
import { Link } from 'react-router-dom';
import FloatLabel from '../floatLabel/floatLabel';
import './index.scss';
import GlobalSearch from '../GlobalSearch/index';

const Header = (props) => {
  const { fields, sitecoreContext, rendering } = props;
  const isEditing = sitecoreContext?.pageEditing;
  const [openMenu, setOpenMenu] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const [openLevelTwoMenu, setOpenLevelTwoMenu] = useState(null);
  let lastScroll = 0;

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
    document.body.classList.toggle('open-navigation');
  };

  const showSubMenu = (id) => {
    if (openSubMenu === id || (openSubMenu && id === undefined)) {
      setOpenSubMenu(null);
    }
    if (openSubMenu !== id) {
      setOpenSubMenu(id);
    }
  };

  const closeHeaderOut = () => {
    setOpenMenu(false);
    if (document.body.classList.contains('open-navigation')) {
      document.body.classList.remove('open-navigation');
    }
  };

  const showLevelTwoNav = (dataId) => {
    if (openLevelTwoMenu === dataId || (openLevelTwoMenu && dataId === undefined)) {
      setOpenLevelTwoMenu(null);
    }
    if (openLevelTwoMenu !== dataId) {
      setOpenLevelTwoMenu(dataId);
    }
  };

  const headerSticky = () => {
    const body = document.body;
    const currentScroll = window.pageYOffset;
    if (currentScroll >= 0) {
      if (currentScroll === 0) {
        body.classList.remove('header__scroll-up');
        return;
      } else if (currentScroll > lastScroll && !body.classList.contains('header__scroll-down')) {
        // down

        body.classList.remove('header__scroll-up');
        body.classList.add('header__scroll-down');
      } else if (currentScroll < lastScroll && body.classList.contains('header__scroll-down')) {
        // up
        body.classList.remove('header__scroll-down');
        body.classList.add('header__scroll-up');
      }
      lastScroll = currentScroll;
    } else {
      body.classList.remove('header__scroll-up');
    }
  };

  const toggleLink = () => {
    const menusubBtns = document.querySelectorAll('.header__subnav-list-item-btn');
    menusubBtns.forEach((menuBtn, key) => {
      menuBtn.addEventListener('click', (e) => {
        const target = e.currentTarget;
        target.previousSibling.classList.toggle('header__subnav-list-item-btn-link-show');
        menusubBtns.forEach(function (ell, els) {
          if (key !== els) {
            ell.previousSibling.classList.remove('header__subnav-list-item-btn-link-show');
          }
        });
      });
    });
  };

  useEffect(() => {
    const Comp = document.querySelector('#Header');
    var anchorscall = Comp.querySelectorAll('a,button');
    Array.prototype.forEach.call(anchorscall, function (element, index) {
      if (element.innerHTML != "") {
        var id = "Header" + "-ds:" + rendering.dataSource + element.innerHTML.replaceAll(" ", "-");

        element.setAttribute("id", id);
      }
    });
    window.addEventListener('scroll', () => {
      headerSticky();
    });
    toggleLink();
    FloatLabel.init();
    return () => {
      window.removeEventListener('scroll', headerSticky);
      document.body.classList.remove('open-navigation');
      document.body.classList.remove('header__scroll-up');
      document.body.classList.remove('header__scroll-down');
      document.removeEventListener('click', toggleMenu, false);
      setOpenMenu(false);
    };
  }, []);

  return (
    <>
      <header
        className={`header ${openMenu ? 'header__open-menu' : ''} ${isEditing ? 'header-experience-editor' : ''
          }`}
      >
        <a href="#main" className="header__skip-menu">
          Skip To Main Menu
        </a>
        <div className="header__container" id="Header">
          <div className="header__inner-contain">
            {fields?.Logo?.value?.src && (
              <div className="header-logo-contain">
                <Link
                  to={fields?.LogoLink?.value?.href == '' ? '/' : fields?.LogoLink?.value?.href}
                  aria-label="Logo"
                >
                  <Image media={fields?.Logo} id="headerLogo" />
                </Link>
              </div>
            )}
            <div className="header__inner-container">
              <GlobalSearch />
              {fields?.children.length && (
                <>
                  <div className="header__menu-btn-contain">
                    <button
                      className={`header__menu-btn ${openMenu ? 'header__menu-btn-open' : ''}`}
                      aria-expanded={openMenu ? true : false}
                      aria-label="Expand or collapse main menu"
                      aria-controls="HeaderNav"
                      onClick={toggleMenu}
                    >
                      <span className="header__menu-btn-container">
                        <span className="header__menu-btn-bar-one" aria-hidden="true" />
                        <span className="header__menu-btn-bar-two" aria-hidden="true" />
                      </span>
                      <span className="sr-only">Toggle to see navigation</span>
                    </button>
                  </div>

                  <div
                    className="header__nav-contain"
                    id="HeaderNav"
                    style={{ visibility: `${openMenu ? 'visible' : 'hidden'}` }}
                    aria-hidden={openMenu ? false : true}
                    tabIndex={openMenu ? 0 : -1}
                  >
                    <nav className="header__nav">
                      <ul className="header__nav-list">
                        {fields?.children.map((navigationLink, index) => {
                          return (
                            <li className="header__nav-list-item" key={`navigationLink-${index}`}>
                              {navigationLink?.fields?.Link?.value?.href && (
                                <RoutableLink
                                  field={navigationLink?.fields?.Link}
                                  className="header__nav-list-item-link"
                                  onClick={toggleMenu}
                                />
                              )}
                              {navigationLink?.fields?.children.length ? (
                                <>
                                  <button
                                    className={`header__nav-list-item-btn ${`navigationSublink-${index}` === openSubMenu
                                        ? 'header__nav-list-item-btn-open'
                                        : ''
                                      }`}
                                    aria-controls={`navigationSublink-${index}`}
                                    onClick={() => {
                                      showSubMenu(`navigationSublink-${index}`);
                                    }}
                                  />

                                  <div
                                    className={`header__subnav-contain ${`navigationSublink-${index}` === openSubMenu
                                        ? 'header__subnav-menu-open'
                                        : ''
                                      }`}
                                    id={`navigationSublink-${index}`}
                                  >
                                    <ul className="header__subnav-list">
                                      {navigationLink?.fields?.children.map(
                                        (subNavigation, key) => {
                                          return (
                                            <li
                                              className="header__subnav-list-item"
                                              key={`subNavigation-${key}`}
                                            >
                                              {subNavigation?.fields?.Link?.value?.href && (
                                                <RoutableLink
                                                  field={subNavigation?.fields?.Link}
                                                  className={`header__subnav-list-item-link ${subNavigation?.fields?.children.length > 0
                                                      ? 'header__subnav-list-item-link-subnav'
                                                      : ''
                                                    }`}
                                                  onClick={toggleMenu}
                                                />
                                              )}
                                              {subNavigation?.fields?.children.length ? (
                                                <>
                                                  <button
                                                    className={`header__subnav-list-item-btn ${`subNavigation-${key}-${index}` ===
                                                        openLevelTwoMenu
                                                        ? 'header__subnav-list-item-btn-open'
                                                        : ''
                                                      }`}
                                                    aria-expanded={
                                                      `subNavigation-${key}-${index}` ===
                                                        openLevelTwoMenu
                                                        ? true
                                                        : false
                                                    }
                                                    aria-controls={`subNavigation-${key}-${index}`}
                                                    onClick={() => {
                                                      showLevelTwoNav(
                                                        `subNavigation-${key}-${index}`
                                                      );
                                                    }}
                                                  />

                                                  <div
                                                    className={`header__subnav-menu-contain ${`subNavigation-${key}-${index}` ===
                                                        openLevelTwoMenu
                                                        ? 'header__subnav-menu-contain-open'
                                                        : ''
                                                      }`}
                                                    aria-hidden={
                                                      `subNavigation-${key}-${index}` ===
                                                        openLevelTwoMenu
                                                        ? false
                                                        : true
                                                    }
                                                    id={`subNavigation-${key}-${index}`}
                                                  >
                                                    <ul className="header__subnav-menu">
                                                      {subNavigation?.fields?.children.map(
                                                        (subNavLevelTwoLink, index) => {
                                                          return (
                                                            <li
                                                              className="header__subnav-menu-item"
                                                              key={`subNavLevelTwoLink-${index}`}
                                                            >
                                                              <RoutableLink
                                                                field={
                                                                  subNavLevelTwoLink?.fields?.Link
                                                                }
                                                                className="header__subnav-list-item-link"
                                                                onClick={toggleMenu}
                                                              />
                                                            </li>
                                                          );
                                                        }
                                                      )}
                                                    </ul>
                                                  </div>
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    </nav>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </header>
      <div
        className={`header__overlay ${openMenu ? 'header__overlay-open' : ''}`}
        aria-hidden="true"
        onClick={closeHeaderOut}
      />
    </>
  );
};

export default Header;
