import React, { useEffect, useRef } from 'react';
import './index.scss';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { default as Button } from '../Button';
import { RoutableLink } from '../../react/RoutableLink';
import { Link } from 'react-router-dom';
import { preloadImages } from './utils';
import { Navigation } from './navigation';
import { Slideshow } from './slideshow';

const FlyInGallery = (props) => {
  const { Title, ViewAllCTA, children } = props.fields;
  const compRef = useRef(null);

  useEffect(() => {
    // Preload all images
    preloadImages('.slide__img', compRef).then(() => {
      // initialize the slideshow and navigation
      const slideshow = new Slideshow(compRef?.current?.querySelector('.slideshow'));
      const navigation = new Navigation(compRef?.current?.querySelector('.slides-nav'));
      // navigation events
      navigation.DOM.ctrls.next.addEventListener('click', () => slideshow.next());
      navigation.DOM.ctrls.prev.addEventListener('click', () => slideshow.prev());
      setInterval(() => {
        slideshow.next();
      }, 7000);
      // set the initial navigation current slide value
      navigation.updateCurrent(slideshow.current);
      // set the navigation total number of slides
      navigation.DOM.total.innerHTML = slideshow.slidesTotal;
      // when a new slide is shown, update the navigation current slide value
      slideshow.on('updateCurrent', (position) => navigation.updateCurrent(position));
    });
  }, [compRef]);

  return (
    <section ref={compRef} className="fig-container">
      <div className="frame">
        <div>
          <Text field={Title} tag="h2" className="fig-eyebrow" />
          <nav className="slides-nav fig-pagination">
            <div className="fig-controls">
              <button
                className="slides-nav__button slides-nav__button--prev fig-control--prev"
                aria-label="Previous slide"
              >
                <span className="sr-only">Click to see Previous slide</span>
                <svg width="35" height="35" viewBox="0 0 35 35" xmlns="http://www.w3.org/2000/svg">
                  <g
                    transform="matrix(-1 0 0 1 35 0)"
                    stroke="#FFF"
                    strokeWidth="2"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <circle cx="17.5" cy="17.5" r="16.5" />
                    <path d="m13.462 8.974 8.974 8.998-8.928 8.951" />
                  </g>
                </svg>
              </button>
              <button
                className="slides-nav__button slides-nav__button--next fig-control--next"
                aria-label="Next slide"
              >
                <span className="sr-only">Click to see Next slide</span>
                <svg width="35" height="35" viewBox="0 0 35 35" xmlns="http://www.w3.org/2000/svg">
                  <g stroke="#FFF" strokeWidth="2" fill="none" fillRule="evenodd">
                    <circle cx="17.5" cy="17.5" r="16.5" />
                    <path d="m13.462 8.974 8.974 8.998-8.928 8.951" />
                  </g>
                </svg>
              </button>
            </div>
            <div className="slides-nav__index fig-count">
              <span className="slides-nav__index-current fig-count-current">1</span>/
              <span className="slides-nav__index-total fig-count-total">5</span>
            </div>
          </nav>
        </div>
        <Button field={ViewAllCTA} variant={'fig-viewAll'} />
      </div>
      <div className="slideshow">
        {children?.length > 0 &&
          children.map(({ fields }, index) => {
            const { Title, Title1, CTA, GalleryImage } = fields || {};
            return (
              <figure key={index} className="slide">
                <div className="slide__img-wrap">
                  <div
                    className="slide__img"
                    style={{ backgroundImage: `url(${GalleryImage?.value?.src})` }}
                  ></div>
                </div>
                <figcaption className="fig-textContainer slide__caption">
                  <h2 className="slides__caption-headline">
                    {CTA?.value?.href ? (
                      <Link
                        to={CTA?.value?.href}
                        target={CTA.value?.linktype == 'external' ? '__blank' : ''}
                        rel={CTA.value?.linktype == 'external' ? 'noopener noreferrer' : ''}
                      >
                        <span className="text-row">
                          <Text field={Title} tag="span" className="description" />
                        </span>
                        <span className="text-row">
                          <Text field={Title1} tag="span" className="description" />
                        </span>
                      </Link>
                    ) : (
                      <>
                        <span className="text-row">
                          <Text field={Title} tag="span" className="description" />
                        </span>
                        <span className="text-row">
                          <Text field={Title1} tag="span" className="description" />
                        </span>
                      </>
                    )}
                  </h2>
                  {CTA?.value?.text && (
                    <RoutableLink
                      field={CTA}
                      className="operationSmile slides__caption-link"
                      target={CTA.value?.linktype == 'external' ? '__blank' : ''}
                      rel={CTA.value?.linktype == 'external' ? 'noopener noreferrer' : ''}
                    />
                  )}
                </figcaption>
              </figure>
            );
          })}
      </div>
    </section>
  );
};

export default FlyInGallery;
