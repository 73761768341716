import React, { useEffect, useRef } from 'react';
import './index.scss';
import { Text } from '@sitecore-jss/sitecore-jss-react';

const AnimatedStatistics = ({ fields }) => {
  const { Title, EnableAnimation, Theme, children = [] } = fields;
  const AnimatedRef = useRef();
  let flag = true;

  const numberAnimation = () => {
    const statistics = AnimatedRef?.current;
    let valueDisplays = statistics?.querySelectorAll(
      '.animated-statistics__items-lists-content-number'
    );
    if (valueDisplays.length > 0) {
      if (EnableAnimation?.value == true) {
        valueDisplays.forEach((block, index) => {
          const observer = new IntersectionObserver(
            (entries) => {
              entries.forEach((entry) => {
                if (entry.intersectionRatio > 0) {
                  if (flag) {
                    flag = false;
                    Animate(0, valueDisplays);
                    observer.unobserve(document.querySelector('.animationStatistics'));
                  }
                }
              });
            },
            { threshold: 0.6 }
          );
          observer.observe(block);
        });
      } else {
        valueDisplays.forEach((value) => {
          const Decimal = value.getAttribute('data-decimalvalue');
          if (Decimal == 'false') {
            let endValue = parseInt(value.getAttribute('data-val'));
            value.textContent = thousandSeparator(endValue);
          } else {
            let endValue = parseFloat(value.getAttribute('data-val'));
            value.textContent = thousandSeparator(endValue);
          }
        });
      }
    }
  };

  function Animate(index, item) {
    const statistics = AnimatedRef?.current;
    let interval = 500;
    const valueColor = statistics?.querySelectorAll(
      '.animated-statistics__items-lists-content-number'
    );
    const descColor = statistics?.querySelectorAll(
      '.animated-statistics__items-lists-content-desc'
    );
    const showSymbol = statistics?.querySelectorAll(
      '.animated-statistics__items-lists-content-symbol'
    );
    const showPrefix = statistics?.querySelectorAll(
      '.animated-statistics__items-lists-content-prefix'
    );
    const Decimal = item[index].getAttribute('data-decimalvalue');
    let startingValue, endValue;

    if (Decimal == 'false') {
      startingValue = parseInt(item[index].getAttribute('data-startingval')) - 1;
      endValue = parseInt(item[index].getAttribute('data-val'));
      let duration = Math.floor(interval / endValue);
      let counter = setInterval(function () {
        startingValue += 1;
        item[index].textContent = thousandSeparator(startingValue);
        if (startingValue == endValue || endValue <= startingValue) {
          valueColor[index].classList.add('animation');
          descColor[index].classList.add('animation');
          showPrefix[index].classList.add('show');
          showSymbol[index].classList.add('show');
          clearInterval(counter);
          //  if (index != item.length - 1) {
          //  Animate(index + 1, item);
          //  }
        }
      }, duration);
    } else {
      startingValue = parseFloat(item[index].getAttribute('data-startingval'));
      endValue = parseFloat(item[index].getAttribute('data-val'));
      let duration = Math.floor(interval / endValue);
      let counter = setInterval(function () {
        startingValue += 0.1;
        item[index].textContent = thousandSeparator(startingValue.toFixed(1));
        if (startingValue == endValue || endValue <= startingValue) {
          item[index].textContent = thousandSeparator(endValue);
          valueColor[index].classList.add('animation');
          descColor[index].classList.add('animation');
          showSymbol[index].classList.add('show');
          showPrefix[index].classList.add('show');
          clearInterval(counter);
          //    if (index != item.length - 1) {
          //       Animate(index + 1, item);
          //  }
        }
      }, duration);
    }
    setTimeout(() => {
      let midvalue = (endValue - startingValue) / 2;
      if (midvalue) {
        if (index != item.length - 1) {
          Animate(index + 1, item);
        }
      }
    }, 500);
  }

  function thousandSeparator(n, sep) {
    if (n) {
      function reverse(text) {
        return text.split('').reverse().join('');
      }

      var rx = /(\d{3}(?!.*\.|$))/g;

      if (!sep) {
        sep = ',';
      }

      return reverse(reverse(n.toString()).replace(rx, '$1' + sep));
    }
  }

  useEffect(() => {
    numberAnimation();
  }, []);

  return (
    <div
      id="animationStatisticsPage"
      className={` animationStatistics ${
        Theme?.fields?.Text?.value.toLowerCase() == 'white'
          ? 'animated-statistics-container__whitebackground'
          : 'animated-statistics'
      } ${
        EnableAnimation?.value == false && Theme?.fields?.Text?.value.toLowerCase() == 'blue'
          ? 'animated-statistics-container__bluebackground '
          : ''
      }`}
      ref={AnimatedRef}
    >
      <div className="animated-statistics-container">
        {Title?.value && (
          <h5 className="animated-statistics__title">
            <Text field={Title} />
          </h5>
        )}
        {children.length > 0 && (
          <ul className="animated-statistics__items">
            {children.map((item, key) => {
              return (
                <li key={key} className="animated-statistics__items-lists">
                  <div className="animated-statistics__items-lists-content">
                    <div className="animated-statistics__items-lists-content-prefix">
                      {item.fields.Prefix.value}
                    </div>
                    <div
                      className="animated-statistics__items-lists-content-number"
                      data-val={item.fields.StatisticsNumber.value}
                      data-startingval={item.fields.AnimationStartingNumber.value || 0}
                      data-decimalvalue={
                        item.fields.AnimateInDecimal.value == false ? 'false' : 'true'
                      }
                    >
                      {thousandSeparator(item.fields.AnimationStartingNumber.value)}
                    </div>

                    <div className="animated-statistics__items-lists-content-symbol">
                      {item.fields.StatisticsSymbol.value}
                    </div>
                  </div>
                  <p className="animated-statistics__items-lists-content-desc">
                    {item.fields.StatisticsText.value}
                  </p>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};

export default AnimatedStatistics;
