import React, { forwardRef, useEffect } from 'react';
import './index.scss';

const EbookModal = forwardRef(function EbookModal(props, ref) {
  const closeEBookModal = (e) => {
    const target = e.currentTarget;
    target.closest('.ebook-modal-contain').classList.remove('show-ebook-modal');
  };

  const closeEBookModalOutside = (event) => {
    const openEbookmodal = document.querySelector('.ebook-modal-contain.show-ebook-modal');
    if (event.target === openEbookmodal) {
      openEbookmodal.classList.remove('show-ebook-modal');
    }
  };
  useEffect(() => {
    window.addEventListener('click', closeEBookModalOutside, false);

    return () => {
      window.removeEventListener('click', closeEBookModalOutside);
    };
  }, []);

  return (
    <div
      className={`ebook-modal-contain ${props.className}`}
      role="dialog"
      aria-modal="true"
      ref={ref}
    >
      <div className="ebook-modal">
        <button
          className="ebook-modal__close-btn"
          title="click here to close modal"
          onClick={closeEBookModal}
        >
          <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <g stroke="#FFF" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="square">
        <path d="M2.354 17.91 17.91 2.354M2.354 2.353 17.91 17.91"/>
    </g>
</svg>

        </button>
        <div className="ebook-modal-inner-contain">{props.children}</div>
      </div>
    </div>
  );
});

export default EbookModal;
