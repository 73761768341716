import React, { useState, useEffect } from 'react';
import './index.scss';
import { useGLobalSearchQuery } from '../../graphql/hooks/useGLobalSearchQuery';

const GlobalSearchAutoComplete = (isMobile = false) => {

  const page = 1;
  const dataPerPage = 10;
  const [searchText, setSearchText] = useState("");
  const [currentData, setCurrentData] = useState([]);
  const [params, setParams] = useState({});
  const [isSearch, setIsSearch] = useState(false);

  const { data: globalSearchData } = useGLobalSearchQuery({
    page,
    perPage: dataPerPage,
    rootItem: '01B4180A-FF6C-4EA5-98C0-CB6BEE9BC01F',
    params: params,
  });

  useEffect(() => {
    if (globalSearchData && isSearch) {
      const responseData = Array.from(globalSearchData?.items);
      const filterData = responseData.filter(v => v.title !== null);
      const finalData = filterData.length > 10 ? filterData.slice(0, dataPerPage - 1) : filterData;
      setCurrentData(finalData || []);
    }
  }, [globalSearchData]);

  useEffect(() => {
    if (searchText && searchText.length > 2) {
      const getData = setTimeout(() => {
        setParams({ keyword: searchText });
        setIsSearch(true);
      }, 500)
      return () => clearTimeout(getData)
    }
    else {
      setCurrentData([]);
      setIsSearch(false);
    }
  }, [searchText]);

  const handleSVGClick = () => {
    const ele = document.getElementById('global-search_field');
    const eleBody = document.getElementsByClassName('global-search-container_body')[0];
    const eleBodyMobile = document.getElementsByClassName('global-search-container-mobile_body')[0];

    ele.classList.toggle('active');
    eleBodyMobile.classList.toggle('global-search-container-mobile_body_active');
    eleBody.classList.toggle('global-search-container_body_active');

    if (ele.classList.contains('active')) {
      ele.focus();
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      window.location.href = `${window.location.origin}/search?q=${searchText}`;
    }
  }

  const searchMappingText = (searchText = "", resultText = "") => {
    if (!resultText) {
      return "";
    }

    return `<a href='/search?q=${encodeURIComponent(resultText)}' class='global-search-container_list_item_link'>${resultText}</a>`;
  }

  return (
    <>
      <div className={isMobile ? 'global-search_wrapper global-search_wrapper_mobile' : 'global-search_wrapper'}>
        <input type="text" class="global-search_wrapper_field" id='global-search_field' style={{ width: isMobile ? '100%' : '0' }} onChange={(event) => setSearchText(event.target.value)} onKeyDown={handleKeyDown}/>
        <svg onClick={handleSVGClick} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" fill='#fff' width="25px" height="48px"><path d="M 27 9 C 17.075 9 9 17.075 9 27 C 9 36.925 17.075 45 27 45 C 31.129213 45 34.9263 43.587367 37.966797 41.240234 L 51.048828 54.322266 C 51.952828 55.226266 53.418266 55.226266 54.322266 54.322266 C 55.226266 53.418266 55.226266 51.952828 54.322266 51.048828 L 41.240234 37.966797 C 43.587367 34.9263 45 31.129213 45 27 C 45 17.075 36.925 9 27 9 z M 27 13 C 34.719 13 41 19.281 41 27 C 41 34.719 34.719 41 27 41 C 19.281 41 13 34.719 13 27 C 13 19.281 19.281 13 27 13 z" /></svg>
      </div>
      <div className='global-search-container_body'>
        <ul className='global-search-container_list'>
          {
            currentData.length > 0 && currentData.map((item, index) => {
              return (
                item.title != undefined && item.title != null ?
                  <li key={'global_link_' + index} className='global-search-container_list_item'>
                    <a href={`/search?q=${encodeURIComponent(item.title)}`} class='global-search-container_list_item_link'>{item.title}</a>
                  </li>
                  : ""
              )
            })
          }
        </ul>
      </div>
    </>)
};

export default GlobalSearchAutoComplete;