import React from 'react';

const FieldValidationErrors = (props) => {
  const { errors } = props; 
  if (!errors || errors.length === 0) {
    return null;
  }

  return (
    <div className="invalid">
      {errors.map((error, index) => (
        <p key={index}>{error}</p>
      ))}
    </div>
  );
};

export { FieldValidationErrors };
