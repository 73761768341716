import gql from 'graphql-tag';

export const STANDALONE_SEARCH = gql`
 query STANDALONE_SEARCH(
    $rootItem: String!
    $keyword: String!
    $sortBy: String!
    $sortDesc: Boolean
    $first: Int
    $after: String!
)
{
  search(
    keyword: $keyword, 
    rootItem: $rootItem
    sortBy: $sortBy
    sortDesc: $sortDesc,
    first: $first
    after: $after
  ){
    results {
      totalCount
      items {
        title: field(name: "title")
        tag: field(name: "tag")
        description: field(name: "description")
        item {
          link: field(name: "cta link") {
            ... on LinkField {
              jss
            }
          }
        }
      }
    }
  }
}
`;